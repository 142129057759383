
import Vue from "vue";

import Harvest from "@/models/harvest";
import Growing from "@/models/growing";
import CottonConfigurations from "@/models/cottonConfigurations";

import { mapGetters, mapActions } from "vuex"; // mapState
import { profitService } from "@/services/profit";
import { ISimpleTableItens } from "@/types/profit-types";
import { ProfitCosts } from "@/business/Profit";
import { isValidKeys } from "@/utilsObject";
import { LotInfoProfit } from "@/business/CattleProfit";
import Ownership from "@/models/ownership";

export default Vue.extend({
  props: {
    harvestSelected: Harvest,
    growingSelected: Growing,
    ownershipSelected: Ownership,
    loading: Boolean,
    showAdd: Boolean,
  },
  data() {
    return {
      // armazena localmente
      // alterações nos valores
      // para os grãos
      grainProfit: { cotton_config: {} } as ProfitCosts,
      // para o boi
      cattleProfit: {} as LotInfoProfit,
    };
  },
  methods: {
    ...mapActions({
      updateProfitOwnershipSelected: "UPDATE_PROFIT_OWNERSHIP_SELECTED",
      updateProfitCattleLot: "UPDATE_PROFIT_CATTLE_LOT",
    }),
    // set o prop com novo valor
    setCostValue(item: string, value: string): void {
      const num: number = parseFloat(value.toString().replace(/,/g, "."));
      if (num && !isNaN(num) && typeof num !== "undefined") {
        if (isValidKeys(ProfitCosts, item)) this.grainProfit[item] = num;
        else console.warn("setCostValue: invalid key to object ProfitCosts");
      }
    },
    // set as config da algodão um novo valor
    setCottonConfigValue(item: string, value: string): void {
      const num: number = parseFloat(value.toString().replace(/,/g, "."));
      if (num && !isNaN(num) && typeof num !== "undefined") {
        if (isValidKeys(CottonConfigurations, item)) {
          if (this.grainProfit.cotton_config)
            this.grainProfit.cotton_config[item] =
              item === "seed_price" ? num : num / 100;
        } else
          console.warn(
            "setCottonConfigValue: invalid key to object ProfitCosts"
          );
      }
    },
    setCattleCostValue(item: string, value: string): void {
      const num: number = parseFloat(value.toString().replace(/,/g, "."));
      if (num && !isNaN(num) && typeof num !== "undefined") {
        if (isValidKeys(LotInfoProfit, item)) this.cattleProfit[item] = num;
        else
          console.warn(
            "setCattleCostValue: invalid key to object LotInfoProfit"
          );
      }
    },
    async updateCost(item: string): Promise<void> {
      if (this.grainProfit[item]) {
        this.profitOwnershipSelected[item] = this.grainProfit[item];
        await this.updateProfitOwnershipSelected(this.profitOwnershipSelected);
        this.$emit("reCalcOwnerCost", true);
      }
    },
    async updateCostCattle(item: string): Promise<void> {
      if (this.cattleProfit[item]) {
        this.profitCattleLotSelected[item] = this.cattleProfit[item];
        await this.updateProfitCattleLot(this.profitCattleLotSelected);
        this.$emit("reCalcOwnerCost", true);
      }
    },
    async updateCottonConfig(item: string): Promise<void> {
      if (
        this.grainProfit.cotton_config &&
        this.grainProfit.cotton_config[item]
      ) {
        this.profitOwnershipSelected["cotton_config"][item] =
          this.grainProfit.cotton_config[item];
        await this.updateProfitOwnershipSelected(this.profitOwnershipSelected);
        this.$emit("reCalcOwnerCost", true);
      }
    },
    async saveOrPatch(): Promise<void> {
      const cottonConfigs = this.profitOwnershipSelected.cotton_config;
      // se é uma nova config
      if (!cottonConfigs.id) {
        // add harvest e growing ids
        cottonConfigs.ownership_id =
          this.profitOwnershipSelected.profit_ownership_id;
        cottonConfigs.ownership_id =
          this.profitOwnershipSelected.profit_ownership_id;
        cottonConfigs.harvest_id = this.harvestSelected.id;
      }
      try {
        await profitService.saveCottonConfigs(cottonConfigs);
        this.$alertSuccess("Parâmetros salvos com sucesso!");
        this.$emit("searchProfit");
      } catch (error) {
        this.$alertError("Houve um problema ao salvar os parâmetros!");
      }
    },
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    },
    formatterRS(value: number): string {
      return this.$options.filters?.formatterRealSimple(value);
    }
  },
  computed: {
    ...mapGetters({
      profitOwnershipSelected: "getProfitOwnershipSelected",
      profitCattleLotSelected: "getProfitCattleLotSelected",
    }),
    title(): string {
      if (this.growingSelected.isCattle) return "Custo na Fazenda";
      return "EXW";
    },
    subtitle(): string {
      if (this.growingSelected.isCattle) return "";
      return "Custo na Fazenda";
    },
    fiberEfficiency(): number {
      if (this.growingSelected.symbol === "CT") {
        return parseFloat(
          (
            this.profitOwnershipSelected.cotton_config.fiber_efficiency * 100
          ).toFixed(2)
        );
      }
      return 0;
    },
    seedEfficiency(): number {
      if (this.growingSelected.symbol === "CT") {
        return parseFloat(
          (
            this.profitOwnershipSelected.cotton_config.seed_efficiency * 100
          ).toFixed(2)
        );
      }
      return 0;
    },
    tableItens(): ISimpleTableItens[] {
      if (this.growingSelected.isCattle) {
        return [
          {
            text: "Quantidade de animais",
            currency: "cabeças",
            tooltip: "Total de animais no lote",
            value: "animal_amount",
            decimal: 2,
          },
          {
            text: "Peso de abate",
            currency: "Kg",
            tooltip: "Peso do animal na data do abate",
            value: "slaughter_weight",
            decimal: 2,
            efficiency: {
              text: "Rendimento da carcaça",
              value: "yield_carcass",
            },
          },
          {
            text: "Peso de carcaça",
            currency: "@",
            tooltip: "Peso da carcaça segundo rendimento",
            value: "carcass_weight",
            decimal: 2,
          },
          {
            text: "Custo total do lote",
            currency: "R$/lote",
            tooltip: "Custo total do lote",
            value: "lot_total_cost",
            decimal: 2,
          },
          {
            text: "Custo total por animal",
            currency: "R$/cabeça",
            tooltip: "Custo total por animal",
            value: "animal_total_cost",
            decimal: 2,
          },
          {
            text: "Custo total por arroba",
            currency: "R$/@",
            tooltip: "Custo total por arroba",
            value: "arroba_total_cost",
            decimal: 2,
          },
          {
            text: "Data de abate",
            currency: "",
            tooltip: "Data de abate do lote",
            value: "slaughter_date",
            decimal: 0,
          },
        ];
      }
      if (this.growingSelected.isCotton) {
        return [
          {
            text: "Custo de Produção",
            currency: "R$/ha",
            tooltip: "Custo de Produção",
            value: "production_cost",
            decimal: 2,
          },
          {
            text: "Produtividade",
            currency: "Kg/ha",
            tooltip: "Produtividade por hectare",
            value: "productivity",
            decimal: 0,
          },
          {
            text: "Produtividade da Pluma",
            currency: "@/ha",
            tooltip: "Rendimento da pluma do algodão",
            value: "productivity_fiber",
            decimal: 2,
            efficiency: {
              text: "Rendimento da pluma",
              value: "fiber_efficiency",
            },
          },
          {
            text: "Produtividade do Caroço",
            currency: "Kg/ha",
            tooltip: "Rendimento do caroço do algodão",
            value: "productivity_seed",
            decimal: 0,
            efficiency: {
              text: "Rendimento do caroço",
              value: "seed_efficiency",
            },
          },
          {
            text: "Preço do Caroço",
            currency: "R$/t",
            tooltip: "Valor da venda do caroço por tonelada",
            value: "seed_price",
            decimal: 0,
          },
          {
            text: "Área Plantada",
            currency: "ha",
            tooltip: "Área Plantada",
            value: "total_area",
            decimal: 0,
          },
          {
            text: "Custo EXW",
            currency: "R$/@",
            tooltip: "Dentro da Fazenda",
            value: "exw_cost_rs_arroba",
            decimal: 2,
          },
          {
            text: "Custo EXW",
            currency: "$/@",
            tooltip: "Dentro da Fazenda",
            value: "exw_cost_us_arroba",
            decimal: 2,
          },
          {
            text: "Custo EXW",
            currency: "¢/lb",
            tooltip: "Dentro da Fazenda",
            value: "exw_cost_us_lb",
            decimal: 2,
          },
        ];
      }
      return [
        {
          text: "Custo de Produção",
          currency: "R$/ha",
          tooltip: "Custo de Produção",
          value: "production_cost",
          decimal: 2,
        },
        {
          text: "Produtividade",
          currency: "sc/ha",
          tooltip: "Produtividade",
          value: "productivity",
          decimal: 0,
        },
        {
          text: "Área Plantada",
          currency: "ha",
          tooltip: "Área Plantada",
          value: "total_area",
          decimal: 0,
        },
        {
          text: "Custo EXW",
          currency: "R$/sc",
          tooltip: "Dentro da Fazenda",
          value: "exw_cost_rs_sc",
          decimal: 2,
        },
        {
          text: "Custo EXW",
          currency: "$/sc",
          tooltip: "Dentro da Fazenda",
          value: "exw_cost_us_sc",
          decimal: 2,
        },
        {
          text: "Custo EXW",
          currency: "$/Bushel",
          tooltip: "Dentro da Fazenda",
          value: "exw_cost_us_bs",
          decimal: 2,
        },
      ];
    },
  },
});
