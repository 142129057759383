
import Vue from 'vue';
import SaleTrigger from "@/models/saleTrigger";
import CattleLot from '@/models/cattleLot';

import { mapGetters } from 'vuex';

export default Vue.extend({
  name: "MarginDetailsInfo",
  props: {
    trigger: SaleTrigger,
    cattleLotSelected: CattleLot,
    cattle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cabCarcassValueMargin(margin: number) {
      return {
        cab: this.formatter((margin * this.cattleLotSelected.final_weight), 2),
        kg: this.formatter((margin / 15), 2),
      };
    },
    sackValueMargin(margin: number) {
      return {
        real: (
          (this.profitOwnershipSelected.production_cost + margin) /
          this.profitOwnershipSelected.productivity
        ).toFixed(2),
        dollar: (
          (this.profitOwnershipSelected.production_cost + margin) /
          this.profitOwnershipSelected.productivity /
          this.stateCalculations.dollar
        ).toFixed(2),
      };
    },
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    },
    formatterReal(value: number): string {
      return this.$options.filters?.formatterReal(value);
    }
  },
  computed:{
    ...mapGetters({
      stateCalculations: "getCalculationState",
      profitOwnershipSelected: "getProfitOwnershipSelected",
    })
  }
});
