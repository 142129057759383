
import Vue, { VueConstructor } from "vue";

import FormMixins from "@/mixins/form";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";
import ProfitN1 from "@/models/profitN1";

import { real_op } from "@/currencyOptions";
import { IFormFuncs } from "@/types/form-types";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    harvestSelected: Harvest,
    growingSelected: Growing,
    actualHarvest: String,
    futureHarvest: String,
    loading: Boolean,
  },
  mixins: [FormMixins],
  data() {
    return {
      wizard: 1,
      dialog: false,
      currencyReal: real_op,
      profit: new ProfitN1(),
    };
  },
  methods: {
    goNext(scope: string): void {
      if (scope) {
        const rscope = this.$refs[scope] as IFormFuncs<ProfitN1>;
        if (rscope && rscope.validate && rscope.validate()) {
          this.wizard++;
        }
      } else {
        this.wizard++;
      }
    },
    goBack(): void {
      this.wizard--;
    },
    sendProfit(scope: string): void {
      if (scope) {
        const rscope = this.$refs[scope] as IFormFuncs<ProfitN1>;
        if (rscope && rscope.validate && rscope.validate()) {
          if (this.growingSelected.id) {
            this.profit.fobbings = 8;
            this.profit.harvest_id = this.harvestSelected.id;
            this.profit.growing_id = this.growingSelected.id;

            // Manter compatibilidade
            this.profit.sold_amount = 0;
            this.profit.sold_rs_sc = 0;
            this.profit.sold_us_sc = 0;
            this.profit.sold_value_rs = 0;
            this.profit.sold_value_us = 0;
            this.$emit("reloadFromAnonymous", this.profit);
            this.close();
          } else {
            this.$alertError("A Cultura não pode ser identificada");
            console.error(
              `sendProfit: growingSelected id not found - ${this.growingSelected.id}`
            );
          }
        }
      }
    },
    close(): void {
      this.dialog = false;
    },
  },
});
