
import Vue from "vue";

import Harvest from "@/models/harvest";
import Growing from "@/models/growing";

import { mapGetters, mapMutations, mapActions } from "vuex"; // mapState
import { ISimpleTableItens } from "@/types/profit-types";
import { filterQuotes } from "@/utilsObject";

type priceItems = {
  [keys: string]: number;
  cbot: number;
  basis_fob: number;
  basis_exw_cbot: number;
  b3: number;
}


export default Vue.extend({
  name: "SpotPrincing",
  props: {
    harvestSelected: Harvest,
    growingSelected: Growing,
    loading: Boolean,
    priceVia: {
      type: String,
      default: "ppe",
      required: true,
      validator: (value: string) => {
        return ["ppe", "basis"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      updatedLogistic: 0,
      b3Headers: [
        {
          text: "B3",
          currency: "R$/saca",
          tooltip: "Valor na bolsa B3",
          value: "b3",
          decimal: 2
        },
        {
          text: "Basis EXW",
          currency: "R$/saca",
          tooltip: "Valor do prêmio EXW",
          value: "basis_exw_b3",
          decimal: 2
        },
        {
          text: "Flat Price",
          currency: "R$/saca",
          tooltip: "Flat price via Basis Local",
          value: "b3_flat_price_local_basis",
          decimal: 2
        },
        {
          text: "Preço EXW",
          currency: "$/saca",
          tooltip: "Valor Basis Local na fazenda",
          value: "b3_local_basis_exw_us_sc",
          decimal: 2
        },
        {
          text: "Preço EXW",
          currency: "R$/saca",
          tooltip: "Valor Basis Local na fazenda",
          value: "b3_local_basis_exw_rs_sc",
          decimal: 2
        }
      ] as ISimpleTableItens[]
    }
  },
  methods: {
    ...mapMutations(["setSpotLogistic"]),
    ...mapActions({
      recalcPpePrice: "CALC_SPOT_PPE_EXW_PRICE",
      updateProfitOwnershipSelected: "UPDATE_PROFIT_OWNERSHIP_SELECTED",
    }),
    setLogistic(value: string): void {
      const usValue = parseFloat(value.replace(/,/g, '.'));
      if(usValue && !isNaN(usValue) && typeof usValue !== "undefined") {
        this.updatedLogistic = usValue;
      }
    },
    async updateLogistic(): Promise<void> {
      if(this.updatedLogistic && this.updatedLogistic > 0) {
        this.profitOwnershipSelected.logistic = this.updatedLogistic;
        await this.updateProfitOwnershipSelected(this.profitOwnershipSelected);
        await this.recalcPpePrice();
      }
    },
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    }
  },
  computed: {
    ...mapGetters({
      pricing: "getPrincing",
      quotations: "getProQuotations",
      profitOwnershipSelected: "getProfitOwnershipSelected",
    }),
    subtitle(): string {
      return this.priceVia === "ppe" ? "PPE" : "Basis Local";
    },
    icon(): string {
      return this.priceVia === "ppe" ? "mdi-ferry" : "mdi-home-silo";
    },
    prices(): Array<priceItems> {
      if(this.growingSelected.isCorn) {
        return {
          ...this.pricing,
          cbot: filterQuotes('cbot', this.quotations)?.value || 0,
          b3: filterQuotes('b3', this.quotations)?.value || 0,
          basis_fob: filterQuotes('basisFob', this.quotations)?.value || 0,
          basis_exw_cbot: filterQuotes('basisCbot', this.quotations)?.value || 0,
          basis_exw_b3: filterQuotes('basisB3', this.quotations)?.value || 0,
        };
      }
      if(this.growingSelected.isCotton) {
        return {
          ...this.pricing,
          nybot: filterQuotes('nybot', this.quotations)?.value || 0,
          basis_fob: filterQuotes('basisFob', this.quotations)?.value || 0,
        };
      }
      return {
        ...this.pricing,
        cbot: filterQuotes('cbot', this.quotations)?.value || 0,
        basis_fob: filterQuotes('basisFob', this.quotations)?.value || 0,
        basis_exw_cbot: filterQuotes('basisCbot', this.quotations)?.value || 0,
      };
    },
    hearders(): ISimpleTableItens[] {
      if (this.priceVia == "ppe") {
        if(this.growingSelected.isCotton)
          return [
            {
              text: "NYBOT",
              currency: "¢/lb",
              tooltip: "Valor na bolsa de Nova Iorque",
              value: "nybot",
              decimal: 4
            },
            {
              text: "Basis FOB",
              currency: "¢/lb",
              tooltip: "Valor do prêmio FOB",
              value: "basis_fob",
              decimal: 2
            },
            {
              text: "Flat Price",
              currency: "¢/lb",
              tooltip: "Flat price via PPE",
              value: "nybot_flat_price",
              decimal: 4
            },
            {
              text: "Preço EXW",
              currency: "$/@",
              tooltip: "Valor PPE na fazenda",
              value: "nybot_ppe_exw_us_arroba",
              decimal: 2
            },
            {
              text: "Preço EXW",
              currency: "R$/@",
              tooltip: "Valor PPE na fazenda",
              value: "nybot_ppe_exw_rs_arroba",
              decimal: 2
            }
          ]
        else
          return [
            {
              text: "CBOT",
              currency: "$/Bushel",
              tooltip: "Valor na bolsa de Chicago",
              value: "cbot",
              decimal: 4
            },
            {
              text: "Basis FOB",
              currency: "$/Bushel",
              tooltip: "Valor do prêmio FOB",
              value: "basis_fob",
              decimal: 2
            },
            {
              text: "Flat Price",
              currency: "$/Bushel",
              tooltip: "Flat price via PPE",
              value: "cbot_flat_price_ppe",
              decimal: 4
            },
            {
              text: "Preço EXW",
              currency: "$/saca",
              tooltip: "Valor PPE na fazenda",
              value: "cbot_ppe_exw_us_sc",
              decimal: 2
            },
            {
              text: "Preço EXW",
              currency: "R$/saca",
              tooltip: "Valor PPE na fazenda",
              value: "cbot_ppe_exw_rs_sc",
              decimal: 2
            }
          ]
      }

      return [
        {
          text: "CBOT",
          currency: "$/Bushel",
          tooltip: "Valor na bolsa de Chicago",
          value: "cbot",
          decimal: 4
        },
        {
          text: "Basis EXW",
          currency: "$/Bushel",
          tooltip: "Valor do prêmio FOB",
          value: "basis_exw_cbot",
          decimal: 2
        },
        {
          text: "Flat Price",
          currency: "$/Bushel",
          tooltip: "Flat price via Basis Local",
          value: "cbot_flat_price_local_basis",
          decimal: 4
        },
        {
          text: "Preço EXW",
          currency: "$/saca",
          tooltip: "Valor Basis Local na fazenda",
          value: "cbot_local_basis_exw_us_sc",
          decimal: 2
        },
        {
          text: "Preço EXW",
          currency: "R$/saca",
          tooltip: "Valor Basis Local na fazenda",
          value: "cbot_local_basis_exw_rs_sc",
          decimal: 2
        }
      ]
    }
  }
})
