
import Vue, { VueConstructor } from "vue";

import SaleTriggersMixins from "@/mixins/saleTriggers";
import FormMixins from "@/mixins/form";
import SaleTrigger from "@/models/saleTrigger";
import Ownership from "@/models/ownership";
import Harvest from "@/models/harvest";
import CattleLot from "@/models/cattleLot";
import Growing from "@/models/growing";

import { real_ha_op } from "@/currencyOptions";
import { decimalBRZToNumber } from "@/utilsNumber";

interface ISackValue {
  real: number;
  dollar: number;
}

interface ICabKgMargin {
  cab: number;
  kg: number;
}

export default (
  Vue as VueConstructor<
    Vue &
      InstanceType<typeof SaleTriggersMixins> &
      InstanceType<typeof FormMixins>
  >
).extend({
  props: {
    ownershipSelected: Ownership,
    harvestSelected: Harvest,
    cattleLotSelected: CattleLot,
    growingSelected: Growing,
  },
  mixins: [SaleTriggersMixins, FormMixins],
  data() {
    return {
      // main object v-model
      saleTrigger: new SaleTrigger(),
      // prop margem da tela trebalha separado do obj
      // por causa de validação de não salvar zero
      triggerTypes: [
        {
          value: 'MARGIN_LIMIT',
          text: 'Margin Limit',
        },
        {
          value:'STOP_LIMIT',
          text: 'Stop Limit',
        }
      ],
      margin: undefined as number | undefined,
      // Soma do estoque restante
      stockLeft: undefined as number | undefined,
      // Indica se esta sem estoque
      withoutStock: false,
      // Indica se é uma edição/update
      fromEdit: false,
      // Se é uma edição/update
      // guarda a valor antigo para verificação
      oldSell: 0,
      // Conf da moeda
      currencyOp: real_ha_op,
      // tema do vuetify
      theme: this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes.dark
        : this.$vuetify.theme.themes.light,
      // Rules
      marginRules: [
        (value: string) => !!value || "Informe quanto quer ganhar por ha",
      ] as Array<(x: string) => boolean | string>,
      triggerTypeRules: [(value: string) => !!value || "Informe o tipo de gatilho",],
      sellRules: [(value: string) => !!value || "Informe o percentual do total a ser vendido",],
    };
  },
  created() {
    if (!this.saleTrigger.id) {
      this.saleTrigger.trigger_type = 'MARGIN_LIMIT'
    }
  },
  methods: {
    validateStock(fromEdit: boolean): boolean {
      if (this.stockLeft) {
        if (fromEdit) {
          let reamains = 0;
          if (this.saleTrigger.to_sell > this.oldSell) {
            reamains = this.saleTrigger.to_sell - this.oldSell;
            return reamains <= this.stockLeft;
          } else return true;
        } else {
          return this.saleTrigger.to_sell <= this.stockLeft;
        }
      } else return true;
    },
    save(): void {
      this.marginRules.push((value: string) => {
        if (!value) return true;
        return (
          !!decimalBRZToNumber(value) ||
          "Valor da margem deve ser maior que zero"
        );
      });
      if (this.validate()) {
        //Checa o stock de acordo com a ação (add, edit)
        if (this.validateStock(this.fromEdit)) {
          if (this.user?.isAnonymous) this.saveInStore();
          else this.saveInBD();
        } else {
          let message = "";
          if (this.fromEdit) {
            message =
              "O valor informado para VENDA supera o estoque disponível";
          } else {
            message =
              "A VENDA deve ser igual ou menor que " + this.stockLeft + "%";
          }
          this.$alertError(message);
        }
      }
    },
    saveInBD(): void {
      if (this.ownershipSelected.id && this.growingSelected.id) {
        this.saleTrigger.ownership_id = this.ownershipSelected.id;
        if (this.growingSelected.isCattle) {
          // Se é boi, salva o lote
          this.saleTrigger.cattle_lot_id = this.cattleLotSelected.id;
        } else {
          // se é grão salva a safra
          if (this.harvestSelected.isSpot)
            this.saleTrigger.harvest_id = undefined;
          else this.saleTrigger.harvest_id = this.harvestSelected.id;
        }
        this.saleTrigger.growing_id = this.growingSelected.id;
        const msuccess = this.componentsText.mSuccess;
        const merror = this.componentsText.mError;
        this.setResource("sale_trigger");
        this.saveResource(this.saleTrigger)
          .then(() => {
            this.$alertSuccess(msuccess);
            this.$emit("searchSaleTriggers");
            this.close();
          })
          .catch((error) => {
            this.$alertError(merror);
            console.error(error);
          });
      } else
        console.warn(
          `saveInBD: no id - ownership id ${this.ownershipSelected.id} - growing id ${this.growingSelected.id}`
        );
    },
    initialize(): void {
      this.saleTrigger = new SaleTrigger();
      this.withoutStock = false;
      this.fromEdit = false;
      this.oldSell = 0;
    },
    checkStock(left: number) {
      this.stockLeft = left;
      if (typeof this.stockLeft === "number") {
        this.saleTrigger.to_sell = this.stockLeft;
        if (this.stockLeft === 0) {
          this.withoutStock = true;
        }
      }
    },
    toUpdate(item: SaleTrigger) {
      this.show = true;
      this.fromEdit = true;
      this.oldSell = item.to_sell;
      this.$nextTick(() => {
        this.saleTrigger = item;
      });
    },
    // Anonymous -----------
    saveInStore(): void {
      if (this.ownershipSelected.id && this.growingSelected.id) {
        this.saleTrigger.harvest_id = this.harvestSelected.id;
        this.saleTrigger.growing_id = this.growingSelected.id;
        const msuccess = this.componentsText.mSuccess;
        const merror = this.componentsText.mError;
        // Verifica se existe id
        // para atualizar ou inserir
        this.updateSaleTriggersStore(this.saleTrigger)
          .then(() => {
            this.$alertSuccess(msuccess);
            this.close();
            this.$emit("buildTriggers");
          })
          .catch((error) => {
            this.$alertError(merror);
            console.error(error);
          });
      } else
        console.warn(
          `saveInBD: no id - ownership id ${this.ownershipSelected.id} - growing id ${this.growingSelected.id}`
        );
    },
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    },
  },
  computed: {
    componentsText() {
      if (this.saleTrigger?.id) {
        return {
          action: "Atualizar",
          mSuccess: "Gatilho atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o gatilho",
        };
      } else {
        return {
          action: "Adicionar",
          mSuccess: "Gatilho inserido com sucesso!",
          mError: "Houve um problema ao inserir o gatilho",
        };
      }
    },
    // Soja e milho
    sackValueMargin(): ISackValue {
      if (this.saleTrigger?.margin > 0) {
        return {
          real:
            (this.profitOwnershipSelected.production_cost +
              this.saleTrigger.margin) /
            this.profitOwnershipSelected.productivity,
          dollar:
            (this.profitOwnershipSelected.production_cost +
              this.saleTrigger.margin) /
            this.profitOwnershipSelected.productivity /
            this.stateCalculations.dollar,
        };
      }
      return {
        real: 0.0,
        dollar: 0.0,
      };
    },
    // Boi gordo
    cabKgMargin(): ICabKgMargin {
      if (this.saleTrigger?.margin !== 0 && this.cattleLotSelected) {
        return {
          cab: parseFloat(
            (
              this.saleTrigger.margin * this.cattleLotSelected.final_weight
            ).toFixed(2)
          ),
          kg: parseFloat((this.saleTrigger.margin / 15).toFixed(2)),
        };
      }
      return {
        cab: 0.0,
        kg: 0.0,
      };
    },
    flatPriceLargeMsg(): string {
      return this.growingSelected.isCotton
        ? "Quantos ¢/lb faltam para margem"
        : "Quantos $/bu faltam para margem";
    },
    flatPriceSmallMsg(): string {
      return this.growingSelected.isCotton
        ? "¢/lb para margem"
        : "$/bu para margem";
    },
    exchangeProjection(): string | undefined {
      if (this.saleTrigger?.margin && !this.growingSelected.isCattle) {
        return this.calcExchange(
          this.saleTrigger.margin,
          this.growingSelected,
          this.saleTrigger.trigger_type
        );
      }
      return undefined;
    },
    goalProjection(): string | undefined {
      if (this.saleTrigger?.margin) {
        // se é boi
        // verifica se é em arroba ou quilos
        if (this.growingSelected.isCattle) {
          return this.calcGoal(
            this.saleTrigger.margin,
            this.growingSelected,
            this.saleTrigger.trigger_type
          );
        }
        // senão continua para os grãos
        return this.calcGoal(
          this.saleTrigger.margin,
          this.growingSelected,
          this.saleTrigger.trigger_type
        );
      }
      return undefined;
    },
    labelMarginGrains(): string {
      return this.harvestSelected.isSpot ? "Preço*" : "Margem*";
    },
    unitMarginGrains(): string {
      return this.harvestSelected.isSpot ? "R$/sc" : this.currencyOp.prefix;
    },
    // SPOT
    flatpriceFobGoal(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.flatpriceGoalSpot(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceFob,
            this.stateCalculationsSpot.basisFob,
            "ppe",
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcGoal(
            this.saleTrigger.margin,
            this.growingSelected,
            this.saleTrigger.trigger_type,
            'ppe'
          );
        }
      }
      return undefined;
    },
    flatpriceCottonFobGoal(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.flatpriceGoalCottonSpot(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceFob,
            this.stateCalculationsSpot.basisFob,
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcGoal(
            this.saleTrigger.margin,
            this.growingSelected,
            this.saleTrigger.trigger_type
          );
        }
      }
      return undefined;
    },
    flatpriceExwGoal(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.flatpriceGoalSpot(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceExwCbot,
            this.stateCalculationsSpot.basisExwCbot,
            "basis",
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcGoal(
            this.saleTrigger.margin,
            this.growingSelected,
            this.saleTrigger.trigger_type,
            'basisLocal'
          );
        }
      }
      return undefined;
    },
    flatpriceExwGoalB3(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.flatpriceGoalSpotB3(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceExwB3,
            this.stateCalculationsSpot.basisExwB3,
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcGoal(
            this.saleTrigger.margin,
            this.growingSelected,
            this.saleTrigger.trigger_type,
            'b3'
          );
        }
      }
      return undefined;
    },
    exchangeFobGoal(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.exchangeGoalSpot(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceFob,
            "ppe",
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcExchange(
              this.saleTrigger.margin,
              this.growingSelected,
              this.saleTrigger.trigger_type,
              'ppe'
          );
        }
      }
      return undefined;
    },
    exchangeFobCottonGoal(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.exchangeGoalCottonSpot(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceFob,
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcExchange(
            this.saleTrigger.margin,
            this.growingSelected,
            this.saleTrigger.trigger_type
          );
        }

      }
      return undefined;
    },
    exchangeExwGoal(): string | undefined {
      if (this.saleTrigger?.margin) {
        if(this.harvestSelected.isSpot) {
          return this.exchangeGoalSpot(
            this.saleTrigger.margin,
            this.stateCalculationsSpot.flatpriceExwCbot,
            "basis",
            this.saleTrigger.trigger_type
          );
        }else {
          return this.calcExchange(
              this.saleTrigger.margin,
              this.growingSelected,
              this.saleTrigger.trigger_type,
              'basisLocal'
          );
        }
      }
      return undefined;
    },
  },
});
