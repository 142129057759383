
import Vue from "vue";

import Growing from "@/models/growing";
import PricingSelector from "@/components/profit/PricingSelector.vue";

export default Vue.extend({
  name: "CardPricing",
  props: {
    growingSelected: Growing,
    loading: Boolean,
  },
  components: {
    PricingSelector: PricingSelector
  },
})
