
import Vue from "vue";
import { QuoteReference } from "@/business/Quotations";

export default Vue.extend({
  name: "SEAsset",
  props: {
    title: {
      type: String,
      default: "-/-",
    },
    color: {
      type: String,
      default: "yellow darken-3",
    },
    arrow: {
      type: String,
      default: "mdi-minus",
    },
    value: {
      type: Number,
      default: 0,
    },
    decimal: {
      type: Number,
      default: 2,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: QuoteReference,
      default: () => new QuoteReference(),
    },
  },
});
