
import Vue from 'vue';
import SaleTrigger, { SaleTriggerScreen } from "@/models/saleTrigger";
import Ownership from '@/models/ownership';
import CattleLot from '@/models/cattleLot';

import DisplayTriggerType from "./DisplayTriggerType.vue";
import SentTriggerChip from "./SentTriggerChip.vue";
import MarginDetailsInfo from "./MarginDetailsInfo.vue";

import { ItemDataTable } from "@/types/form-types";

export default Vue.extend({
  name: "CattleDataTable",
  props:{
    triggers: Array as () => SaleTriggerScreen[],
    ownershipSelected: Ownership,
    cattleLotSelected: CattleLot,
  },
  components: {
    DisplayTriggerType,
    SentTriggerChip,
    MarginDetailsInfo
  },
  data() {
    return {
      // object usado para mostrar gatilho
      // para desativar/ativar
      saleTriggersUpdate: undefined as SaleTrigger | undefined,
      // Msg para desativar/ativar o gatilho
      updateMsg: undefined as string | undefined,
      headers: [
          {
            text: "Nome",
            tooltip: "Gatilho de venda",
            align: "center",
            value: "name",
            sortable: false,
          },
          {
            text: "Tipo",
            tooltip: "Tipo do gatilho",
            align: "center",
            value: "trigger_type",
            sortable: false,
          },
          {
            text: "Margem (R$/@)",
            tooltip: "Receita esperada por (@)",
            align: "center",
            value: "margin",
            sortable: false,
          },
          {
            text: "Venda",
            tooltip: "Percentual de venda",
            align: "center",
            value: "to_sell",
            sortable: false,
          },
          {
            text: "Preço B3",
            tooltip: "Variação do preço na B3",
            align: "center",
            value: "goal",
            sortable: false,
          },
          {
            text: "Status",
            tooltip: "Situação do gatilho",
            align: "center",
            value: "sent",
            sortable: false,
          },
          {
            text: "Ações",
            tooltip: "Atividades",
            align: "center",
            value: "actions",
            sortable: false,
          },
        ] as ItemDataTable[]
    }
  },
  computed:{
    sumSell(): number {
      if (this.triggers?.length) {
        return this.triggers.reduce(
          (accumulator: number, element: SaleTrigger) =>
            accumulator + element.to_sell,
          0
        );
      }
      return 0;
    },
    stockLeft(): number {
      if (this.sumSell) return 100 - this.sumSell;
      return 100;
    },
  },
  methods: {
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    },
    formatterReal(value: number): string {
      return this.$options.filters?.formatterReal(value);
    }
  }
});
