
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      inset: false,
    };
  },
  methods: {
    close(): void {
      this.dialog = false;
    },
  },
});
