
import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: "NotificationUser",
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    })
  },
  methods: {
    ...mapActions({
      saveUser: "SAVE_USER",
      updateToken: "UPDATE_TOKEN",
    }),
    changeState(feature: string, onOff: boolean): void {
      if (feature === "whats") {
        this.user.send_whats = onOff;
      } else {
        this.user.send_email = onOff;
      }
    },
    savePhone(): void {
      // INSERE COD PAIS HARDCODE
      this.user.country_code = "55";
      this.user.phone_number = this.user.phone_number.replace(/\s/g, ""); // remove blank spaces
      this.user.phone_number = this.user.phone_number.replace(/[{()}]/g, ""); // remove parentheses ()
      this.saveUser(this.user)
        .then(async () => {
          await this.updateToken();
          this.$alertSuccess("Configurações de notificações alteradas!");
        })
        .catch(() => {
          this.$alertError("Houve um problema ao salvar as configurações!");
        });
    },
  },
});
