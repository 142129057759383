
import Vue, { VueConstructor } from "vue";
import ProfitMixins from "@/mixins/profit";
import Growing from "@/models/growing";
import { mapGetters } from "vuex"; // mapState

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof ProfitMixins>>
).extend({
  props: {
    growingSelected: Growing,
    loading: Boolean,
  },
  /**From mixin:
   * - revenuePriceReal
   * - revenuePriceDollar
   * - revenueValueReal
   * - revenueValueDollar
   */
  mixins: [ProfitMixins],
  data() {
    return {
      inset: false,
    };
  },
  computed: {
    ...mapGetters({
      profitMarginSelected: "getProfitMarginSelected",
      profitOwnershipSelected: "getProfitOwnershipSelected",
    }),
    totalCostReal(): number {
      return this.profitOwnershipSelected.production_cost *
              this.profitOwnershipSelected.total_area;
    },
    totalCostDollar(): number {
      return this.totalCostReal /
              this.stateCalculations.dollar;
    },
    revenueAmountReal(): number {
      return this.revenueValueReal - this.totalCostReal;
    },
    revenueAmountDollar(): number {
      return this.revenueValueDollar - this.totalCostDollar;
    },
    revenueAmountRealHa(): number {
      return this.revenueAmountReal / this.profitOwnershipSelected.total_area;
    },
    revenueAmountDollarHa(): number {
      return this.revenueAmountDollar / this.profitOwnershipSelected.total_area;
    },
    roiReal(): number {
      return (this.revenueAmountReal / this.totalCostReal) * 100;
    },
    roiDollar(): number {
      return (this.revenueAmountDollar / this.totalCostDollar) * 100;
    }
  },
});
