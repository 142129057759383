import { mapGetters } from 'vuex';
import { DecimalPrecision } from "@/utilsNumber";

import Growing from "@/models/growing";
import Vue from "vue";

export default Vue.extend({
  props: {
    growingSelected: {
      type: Growing,
      default: () => new Growing()
    },
  },
  data: () => ({
    quots: undefined,
  }),
  methods: {
    decimal(num: number, precision: number): number {
      return DecimalPrecision().round(num, precision);
    },
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    },
    formatterReal(value: number): string {
      return this.$options.filters?.formatterReal(value);
    },
    formatterDollar(value: number): string {
      return this.$options.filters?.formatterDollar(value);
    }
  },
  computed: {
    ...mapGetters({
      prices: "getPrices",
      stateCalculations: "getCalculationState",
      profitIncomeSelected: "getProfitIncomeSelected",
      profitIncomeSelectedFiber: "getProfitIncomeSelectedFiber",
      pricingSelected: "getPrincingSelected",
    }),
    priceReal(): number {
      return this.prices.priceRs;
    },
    priceDollar(): number {
      return this.prices.priceUs;
    },
    soldReal(): number {
      if (this.growingSelected.isCotton || this.growingSelected.isCattle)
        return this.profitIncomeSelected.sold_rs_arroba;

      return this.profitIncomeSelected.sold_rs_sc;
    },
    soldDollar(): number {
      if (this.growingSelected.isCotton)
        return this.profitIncomeSelected.sold_us_arroba;

      return this.profitIncomeSelected.sold_us_sc;
    },
    // Proxy growings
    statementValueReal(): number {
      if(this.growingSelected.isCotton) return this.statementFiberValueRs;
      return this.statementGrainValueReal;
    },
    statementValueDollar(): number {
      if(this.growingSelected.isCotton) return this.statementFiberValueUs;
      return this.statementGrainValueDollar;
    },
    revenuePriceReal(): number {
      if(this.growingSelected.isCotton) return this.revenueFiberPriceReal;
      return this.revenueGrainPriceReal;
    },
    revenuePriceDollar(): number {
      if(this.growingSelected.isCotton) return this.revenueFiberPriceDollar;
      return this.revenueGrainPriceDollar;
    },
    revenueValueReal(): number {
      if(this.growingSelected.isCotton) return this.revenueFiberValueReal;
      return this.revenueGrainValueReal;
    },
    revenueValueDollar(): number {
      if(this.growingSelected.isCotton) return this.revenueFiberValueDollar;
      return this.revenueGrainValueDollar;
    },
    // Grain
    statementGrainValueReal(): number {
      return this.profitIncomeSelected.statement_amount * this.priceReal
    },
    statementGrainValueDollar(): number {
      return this.profitIncomeSelected.statement_amount * this.priceDollar;
    },
    revenueGrainPriceReal(): number {
      return (
        (this.soldReal * this.profitIncomeSelected.sold_amount +
          this.priceReal * this.profitIncomeSelected.statement_amount) /
        (this.profitIncomeSelected.sold_amount +
          this.profitIncomeSelected.statement_amount)
      );
    },
    revenueGrainPriceDollar(): number {
      return (
        (this.soldDollar * this.profitIncomeSelected.sold_amount +
          this.priceDollar * this.profitIncomeSelected.statement_amount) /
        (this.profitIncomeSelected.sold_amount +
          this.profitIncomeSelected.statement_amount)
      );
    },
    revenueGrainValueReal(): number {
      return (
        (this.profitIncomeSelected.sold_amount +
          this.profitIncomeSelected.statement_amount) *
        this.revenuePriceReal
      );
    },
    revenueGrainValueDollar(): number {
      return (
        (this.profitIncomeSelected.sold_amount +
          this.profitIncomeSelected.statement_amount) *
        this.revenuePriceDollar
      );
    },
    // Cotton
    statementFiberValueRs(): number {
      return this.profitIncomeSelectedFiber.statement_amount * this.priceReal;
    },
    statementFiberValueUs(): number {
      return this.profitIncomeSelectedFiber.statement_amount * this.priceDollar;
    },
    revenueFiberPriceReal(): number {
      return ((this.profitIncomeSelectedFiber.sold_rs_arroba * this.profitIncomeSelectedFiber.sold_amount) + (this.priceReal * this.profitIncomeSelectedFiber.statement_amount)) /
        (this.profitIncomeSelectedFiber.sold_amount + this.profitIncomeSelectedFiber.statement_amount);
    },
    revenueFiberPriceDollar(): number {
      return ((this.profitIncomeSelectedFiber.sold_us_arroba * this.profitIncomeSelectedFiber.sold_amount) + (this.priceDollar * this.profitIncomeSelectedFiber.statement_amount)) /
        (this.profitIncomeSelectedFiber.sold_amount + this.profitIncomeSelectedFiber.statement_amount);
    },
    revenueFiberValueReal(): number {
      return (this.profitIncomeSelectedFiber.sold_amount + this.profitIncomeSelectedFiber.statement_amount) * this.revenueFiberPriceReal;
    },
    revenueFiberValueDollar(): number {
      return (this.profitIncomeSelectedFiber.sold_amount + this.profitIncomeSelectedFiber.statement_amount) * this.revenueFiberPriceDollar;
    },
    pirceName(): string {
      if(this.growingSelected.isCotton) return "(ICE + BASIS FOB)";
      if (this.pricingSelected === "basisCbot") {
        return "(CBOT + Basis EXW)";
      } else if (this.pricingSelected === "basisB3") {
        return "(B3 + Basis EXW)";
      }
      return "(CBOT + Basis FOB)";
    },
  }
});