
import Vue from 'vue';
import SaleTrigger, { SaleTriggerScreen } from "@/models/saleTrigger";
import Ownership from '@/models/ownership';
import Harvest from '@/models/harvest';

// Components
import DisplayTriggerType from "./DisplayTriggerType.vue";
import SentTriggerChip from "./SentTriggerChip.vue";

import { ItemDataTable } from "@/types/form-types";

export default Vue.extend({
  name: "GrainDataTable",
  props:{
    triggers: Array as () => SaleTriggerScreen[],
    ownershipSelected: Ownership,
    harvestSelected: Harvest,
  },
  components: {
    DisplayTriggerType,
    SentTriggerChip,
  },
  data() {
    return {
      // object usado para mostrar gatilho
      // para desativar/ativar
      saleTriggersUpdate: undefined as SaleTrigger | undefined,
      // Msg para desativar/ativar o gatilho
      updateMsg: undefined as string | undefined,
    }
  },
  computed:{
    sumSell(): number {
      if (this.triggers?.length) {
        return this.triggers.reduce(
          (accumulator: number, element: SaleTrigger) =>
            accumulator + element.to_sell,
          0
        );
      }
      return 0;
    },
    stockLeft(): number {
      if (this.sumSell) return 100 - this.sumSell;
      return 100;
    },
    headers():ItemDataTable[] {
      let arr = [
        {
          text: "Nome",
          tooltip: "Gatilho de venda",
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: "Tipo",
          tooltip: "Tipo do gatilho",
          align: "center",
          value: "trigger_type",
          sortable: false,
        },
        {
          text: "Venda",
          tooltip: "Percentual de venda",
          align: "center",
          value: "to_sell",
          sortable: false,
        },
        {
          text: "Flat Price (¢/lb)",
          tooltip: "Variação de Flat Price",
          align: "center",
          value: "goal",
          sortable: false,
        },
        {
          text: "Câmbio",
          tooltip: "Variação do Dolar",
          align: "center",
          value: "exchange",
          sortable: false,
        },
        {
          text: "Status",
          tooltip: "Situação do gatilho",
          align: "center",
          value: "sent",
          sortable: false,
        },
        {
          text: "Ações",
          tooltip: "Atividades",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ];
      if (this.harvestSelected.isSpot) {
        arr.splice(2, 0, {
          text: `Preço ("R$/@")`,
          tooltip: `Preço de venda da "arroba"`,
          align: "center",
          value: "margin",
          sortable: false,
        });
      } else {
        arr.splice(2, 0, {
          text: "Margem (R$/ha)",
          tooltip: "Receita esperada por (ha)",
          align: "center",
          value: "margin",
          sortable: false,
        });
      }
      return arr;
    },
  },
  methods: {
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    },
    formatterReal(value: number): string {
      return this.$options.filters?.formatterReal(value);
    }
  }
});
