
import Vue from 'vue';
import SaleTrigger from '@/models/saleTrigger';

export default Vue.extend({
  name: "SentTriggerChip",
  props: {
    trigger: SaleTrigger
  },
  methods: {
    getColor(): string {
      if (!this.trigger.active) return "inspect";
      else if (this.trigger.sent) return "success";
      else return "warning";
    },
    getText(): string {
      if (!this.trigger.active) return "Desativado";
      else if (this.trigger.sent) return "Acionado";
      else return "Pendente";
    },
  },
});
