
import Vue from "vue";

import Growing from "@/models/growing";

interface IPriceCard {
  title: string;
  subtitle: string;
  icon: string;
  identifier: string;
}

export default Vue.extend({
  name: "PricingSelector",
  props: {
    growingSelected: Growing,
    isMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pricing: {
      get(): string {
        return this.$store.state.profit.pricingSelected;
      },
      set (value: string): void {
        this.$store.commit('setProfitPricingSelected', value);
        this.$emit('priceSelected');
      }
    },
    dynamicWidth(): number | undefined {
      if(this.isMenu) {
        return this.growingSelected.isCorn ? 567 : 398
      }
      return undefined;
    },
    cardPrices(): IPriceCard[] {
      if (this.growingSelected.isCorn)
        return [
          {
            title: "PPE",
            subtitle: "CBOT + basis FOB",
            icon: "mdi-ferry",
            identifier: "ppe",
          },
          {
            title: "Basis Local",
            subtitle: "CBOT + basis EXW",
            icon: "mdi-barn",
            identifier: "basisCbot",
          },
          {
            title: "Basis Local",
            subtitle: "B3 + basis EXW",
            icon: "mdi-barn",
            identifier: "basisB3",
          },
        ];
      else
        return [
          {
            title: "PPE",
            subtitle: "CBOT + basis FOB",
            icon: "mdi-ferry",
            identifier: "ppe",
          },
          {
            title: "Basis Local",
            subtitle: "CBOT + basis EXW",
            icon: "mdi-barn",
            identifier: "basisCbot",
          },
        ];
    },
  },
});
