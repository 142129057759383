
import Vue from "vue";

import Growing from "@/models/growing";
import { mapGetters, mapActions } from "vuex"; // mapState
import { isValidKeys } from "@/utilsObject";
import { ProfitCosts } from "@/business/Profit";

export default Vue.extend({
  props: {
    growingSelected: Growing,
    loading: Boolean,
  },
  data() {
    return {
      // armazena localmente
      // alterações nos valores
      logis: {} as ProfitCosts,
    };
  },
  methods: {
    ...mapActions({
      updateProfitOwnershipSelected: "UPDATE_PROFIT_OWNERSHIP_SELECTED",
    }),
    setLogisticValue(item: string, value: string): void {
      const num: number = parseFloat(value.toString().replace(/,/g, "."));
      if (num && !isNaN(num) && typeof num !== "undefined") {
        if (isValidKeys(ProfitCosts, item)) this.logis[item] = num;
        this.logis[item] = num;
      }
    },
    updateLogistic(item: string): void {
      if (this.logis[item]) {
        this.profitOwnershipSelected[item] = this.logis[item];
        this.updateProfitOwnershipSelected(this.profitOwnershipSelected).then(
          () => {
            this.$emit("reCalcOwnerCost", true);
          }
        );
      }
    },
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    }
  },
  computed: {
    ...mapGetters({
      profitOwnershipSelected: "getProfitOwnershipSelected",
    }),
    tableItens() {
      if (this.growingSelected.isCotton) {
        return [
          {
            text: "Frete",
            currency: "R$/t",
            tooltip: "Fazenda - Porto",
            value: "logistic",
          },
          {
            text: "Estufagem",
            currency: "R$/t",
            tooltip: "Custos Portuários",
            value: "stuffing",
          },
          {
            text: "Custo FOB",
            currency: "R$/@",
            tooltip: "Entregue no Navio",
            value: "fob_price_rs_arroba",
          },
          {
            text: "Custo FOB",
            currency: "$/@",
            tooltip: "Entregue no Navio",
            value: "fob_price_us_arroba",
          },
          {
            text: "Custo FOB",
            currency: "¢/lb",
            tooltip: "Entregue no Navio",
            value: "fob_price_us_lb",
          },
        ];
      }
      return [
        {
          text: "Frete",
          currency: "R$/t",
          tooltip: "Fazenda - Porto",
          value: "logistic",
        },
        {
          text: "Fobbings",
          currency: "$/t",
          tooltip: "Custos Portuários",
          value: "fobbings",
        },
        {
          text: "Custo FOB",
          currency: "R$/sc",
          tooltip: "Entregue no Navio",
          value: "fob_price_rs_sc",
        },
        {
          text: "Custo FOB",
          currency: "$/sc",
          tooltip: "Entregue no Navio",
          value: "fob_price_us_sc",
        },
        {
          text: "Custo FOB",
          currency: "$/Bushel",
          tooltip: "Entregue no Navio",
          value: "fob_price_us_bs",
        },
      ];
    },
  },
});
