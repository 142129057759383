import axios from '@/plugins/axios';
import { HistoryProfit } from '@/business/HistoryProfit';
import { IHistoryProfitParams } from '@/types/service-types';
import { intanciateApiData, isValidResponse } from '@/utilsObject';

const getClosedPrices = async (params: IHistoryProfitParams): Promise<HistoryProfit> => {
  const response = await axios.httpApi.get<HistoryProfit>("charts/profit-history", { params });
  if (isValidResponse(response.status)) {
    return intanciateApiData(HistoryProfit, response.data);
  }
  console.error(`Error to get graphic data: ${response.statusText}`);
  return response.data;
};

const getClosedPricesN1 = async (client_id?: number): Promise<HistoryProfit> => {
  let response = undefined
  if (client_id) {
    const params = { user: client_id };
    response = await axios.httpApi.get<HistoryProfit>("charts/profit-history-simple", { params });
  } else {
    response = await axios.httpApi.get<HistoryProfit>("charts/profit-history-simple");
  }
  if (isValidResponse(response.status)) {
    return intanciateApiData(HistoryProfit, response.data);
  }
  console.error(`Error to get simple graphic data: ${response.statusText}`);
  return response.data;
}

export const chartsService = {
  getClosedPrices,
  getClosedPricesN1
}