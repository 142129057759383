
import Vue, { VueConstructor } from "vue";

import Growing from "@/models/growing";
import Harvest from "@/models/harvest";
import Ownership from "@/models/ownership";

import StockExchangeAsset from "./StockExchangeAsset.vue";
import SocketMixins from "@/mixins/socket";
import Business from "@/utilsBusiness";

import { mapGetters, mapActions } from "vuex";
import {
  CattleQuotations,
  ICattleQuotations,
  IQuotes,
  Quotations,
  Quotes,
} from "@/business/Quotations";
import { isValidKeys } from "@/utilsObject";

interface IQuotesInfo extends IQuotes {
  name: string;
  smallName: string;
  unit: string;
  decimal: number;
}

interface ICustomEvent extends Event {
  data?: string;
}

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof SocketMixins>>
).extend({
  name: "StockExchangeMarquee",
  props: {
    ownershipSelected: {
      type: Ownership,
      default: () => new Ownership()
    },
    harvestSelected: {
      type: Harvest,
      default: () => new Harvest()
    },
    growingSelected: {
      type: Growing,
      default: () => new Growing()
    },
  },
  components: {
    StockExchangeAsset: StockExchangeAsset,
  },
  data() {
    return {
      // Cor chip: estável/sem alteração
      stableColor: "yellow darken-3",
      // Cor chip: alta
      upColor: "green",
      // Cor chip: baixa
      downColor: "red",
      // Flecha chip: estável/sem alteração
      stableArrow: "mdi-minus",
      // Flecha chip: alta
      upArrow: "mdi-arrow-up-thick",
      // Flecha chip: baixa
      downArrow: "mdi-arrow-down-thick",
      // EventSource da API
      es: {} as EventSource,
      // define se a marquee para de andar
      pause: false,
      // Config quotes para algodão
      cotton_info: {
        name: "NYBOT",
        unit: "¢/lb",
        decimal: 2,
      } as IQuotesInfo,
      // Config quotes padrão
      default_info: {
        name: "CBOT",
        unit: "$/Bushel",
        decimal: 4,
      } as IQuotesInfo,
    };
  },
  mounted() {
    if (this.$socket.disconnected) {
      this.$socket.client.open();
    }
  },
  sockets: {
    connect(): void {
      console.log("socket connected");
      if (this.$socket.connected) {
        this.$socket.client.emit("join_profit");
      }
    },
    room_joined(data): void {
      console.log(`user has joined the room: ${data}`);
    },
    server_request_update(): void {
      if (this.growingSelected.isCattle) {
        const params = {
          growing: this.growingSelected.nameEnglish.toLowerCase(),
        };
        this.$socket.client.emit("client_update_info", params);
      } else {
        const reference = `deal_${this.growingSelected.nameEnglish.toLowerCase()}_reference`;
        if (isValidKeys(Ownership, reference)) {
          const params = {
            harvest: Business.getHarvestPeriod(this.harvestSelected),
            growing: this.growingSelected.nameEnglish.toLowerCase(),
            reference: this.ownershipSelected[reference],
          };
          this.$socket.client.emit("client_update_info", params);
        } else
          console.warn(
            `update_quotes: not a valid reference for ownership: ${reference}`
          );
      }
    },
    server_quote_result(data): void {
      if (data) {
        if (this.growingSelected.isCattle) {
          const jsonData = JSON.parse(data) as Array<ICattleQuotations>;
          const quotes = jsonData.map((el) => new CattleQuotations(el));
          this.updateCattleQuotationsBanner(quotes);
        } else {
          const jsonData = JSON.parse(data) as Array<IQuotes>;
          const quotations = jsonData.map((el) => new Quotes(el));
          this.updateGlobalQuotations(quotations);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      updateQuotations: "UPDATE_PRO_QUOTATIONS",
      updateFlatPrice: "UPDATE_PROFIT_FLAT_PRICE",
      updatePricing: "UPDATE_PROFIT_PRICING",
      updateCattleQuotationsBanner: "UPDATE_CATTLE_QUOTATIONS_BANNER",
    }),
    setupStream(): void {
      this.es = new EventSource(process.env.VUE_APP_SSE);
      this.es.addEventListener(
        "quotations",
        (event: ICustomEvent) => {
          if (event.data) {
            this.quots = JSON.parse(event.data);
          }
        },
        false
      );
    },
    changeColor(quote: IQuotes): string {
      if (quote) {
        if (quote.arrow > 0) return this.upColor;
        if (quote.arrow < 0) return this.downColor;
      }
      return this.stableColor;
    },
    changeArrow(quote: IQuotes): string {
      if (quote) {
        if (quote.arrow > 0) return this.upArrow;
        if (quote.arrow < 0) return this.downArrow;
      }
      return this.stableArrow;
    },
    async updateGlobalQuotations(newQuotes: Quotations): Promise<void> {
      // atualiza o state das quotations na store
      await this.updateQuotations(newQuotes);
      await this.updatePricing(this.growingSelected.symbol);
      if (!this.harvestSelected.isSpot) {
        this.$emit("reCalcOwnerCost");
      }
    },
  },
  computed: {
    ...mapGetters({
      cattleQuotes: "getCattleBarQuotations",
      quotations: "getProQuotations",
    }),
    filteredQuots(): Array<Quotations> {
      if (this.growingSelected.isCattle) {
        // TODO: REMOVER GAMBIARRA
        const cMonths = ["fev", "mar", "abr", "mai", "jul", "ago", "out", "nov"];
        const gambiarraChen = this.cattleQuotes.filter((el: Quotations) => {
          if (cMonths.includes(el.ref.month_show)) {
            return el;
          }
        });
        return gambiarraChen;
      } else {
        return this.quotations;
      }
    },
    display_info(): IQuotesInfo {
      if (this.growingSelected.isCotton) {
        return this.cotton_info;
      }
      return this.default_info;
    },
    calcMarqueeColValue(): number {
      /**Define a tamanho da coluna da marquee
       * para a mesma ficar alinhada em uma linha
       */
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$vuetify.breakpoint.width < 475) {
          return 7;
        }
        return 9;
      }
      return 10;
    },
  },
  beforeDestroy() {
    if (this.$socket.connected) {
      this.$socket.client.emit("leave_basis");
      console.log("user left the room");
      this.$socket.client.close();
      if (this.$socket.disconnected) console.log("socket disconnect");
    }
  },
});
