
import Vue from "vue";

import Growing from "@/models/growing";
import PricingSelector from "@/components/profit/PricingSelector.vue";

export default Vue.extend({
  name: "MenuPricing",
  props: {
    growingSelected: Growing,
  },
  components: {
    PricingSelector: PricingSelector
  },
  data() {
    return {
      menu: false,
    }
  },
});
