import Vue from "vue";
import Calculations from "@/utilsBusiness";
import { mapGetters, mapMutations, mapActions } from "vuex"; // mapState
import Growing from "@/models/growing";
import { round } from "@/utilsNumber";

export default Vue.extend({
  computed: {
    ...mapGetters({
      saleTriggers: "getSaleTriggers",
      stateCalculations: "getCalculationState",
      stateCalculationsSpot: "getCalculationStateSPOT",
      profitOwnershipSelected: "getProfitOwnershipSelected",
      profitCattleLotPriceSelected: "getProfitCattleLotPriceSelected",
      user: "getLoggedUser",
      pricing: "getPrincing"
    }),
  },
  methods: {
    ...mapMutations(["setResource"]),
    ...mapActions({
      getSaleTriggers: "SEARCH_SALE_TRIGGERS",
      getResource: "SIMPLE_SEARCH",
      deleteSaleTriggers: "DELETE",
      saveResource: "SAVE_UPDATE",
      updateSaleTriggersStore: "UPDATE_SALE_TRIGGERS",
      deleteSaleTriggersStore: "DELETE_SALE_TRIGGERS",
    }),
    formatTriggerReturn(value: number, format = "real", triggerType='MARGIN_LIMIT'): string {
      if (typeof value === "undefined" || value === null || isNaN(value)) {
        return "Erro";
      }
      let result = value;
      const filters = this.$options.filters;
      if(filters){
        switch (format) {
          case "real":
            result = filters.formatterReal(value);
            break;
          case "dollar":
            result = filters.formatterDollar(value);
            break;
          case "cents":
            result = filters.formatterCents(value);
            break;

          default:
            result = filters.formatterReal(value);
            break;
        }
        if (triggerType === 'MARGIN_LIMIT') {
          return value <= 0 ? "Venda" : result.toString();
        }
        else {
          return value >= 0 ? "Venda" : result.toString();
        }
      }else{
        console.warn("formatTriggerReturn: No filters functions");
        return "Erro";
      }
    },
    calcExchange(margin: number, growing: Growing, triggerType='MARGIN_LIMIT', pricing='ppe'): string {
      let dol = 0;

      if (growing.symbol === "CT") {
        dol = Calculations.saleTriggerDollarCotton(margin);
      } else {
        if(pricing === 'basisLocal') {
          dol = Calculations.saleTriggerDollarBasisExw(
            margin,
            this.stateCalculations.flatpriceExwCbot
          );
        }else {
          dol = Calculations.saleTriggerDollar(
            margin,
            this.stateCalculations.flatpriceFob
          );
        }
      }
      return this.formatTriggerReturn(dol, 'real', triggerType);
    },
    calcGoal(margin: number, growing: Growing, triggerType='MARGIN_LIMIT', pricing='ppe'): string {
      let calc = 0;
      if (growing.symbol === "CT") {
        calc = Calculations.saleTriggerPPECotton(margin);
        return this.formatTriggerReturn(calc, "cents", triggerType);
      } else if (growing.symbol === "BC") {
        calc = Calculations.saleTriggerCattleB3(margin);
        return this.formatTriggerReturn(calc, 'real',  triggerType);
      } else {
        let sackValue = 0;
        switch (pricing) {
          case 'ppe':
            sackValue = this.pricing.cbot_ppe_exw_rs_sc;
            break;
          case 'basisLocal':
            sackValue = this.pricing.cbot_local_basis_exw_rs_sc;
            break;
          case 'b3':
            sackValue = this.pricing.b3_local_basis_exw_rs_sc;
            break;

          default:
            sackValue = this.pricing.cbot_ppe_exw_rs_sc;
            break;
        }
        if(growing.isCorn && pricing === 'b3')
          calc = round(Calculations.saleTriggerB3BasisExw(
            margin,
            this.stateCalculations.productionCost,
            this.stateCalculations.productivity,
            sackValue
          ), 2);
        else
          calc = round(Calculations.saleTriggerPPE(margin, sackValue), 2);
      }
      if(pricing === 'b3')
        return this.formatTriggerReturn(calc, "real", triggerType);
      else
        return this.formatTriggerReturn(calc, "dollar", triggerType);
    },
    flatpriceGoalSpot(price: number, flatprice: number, basis: number, pricing: string, triggerType='MARGIN_LIMIT'): string {
      const calc = Calculations.saleTriggerFlatPriceSPOT(
        price,
        flatprice,
        basis,
        this.stateCalculationsSpot.dollar,
        this.stateCalculationsSpot.constants,
        this.stateCalculationsSpot.logistic,
        pricing
      );
      return this.formatTriggerReturn(calc, "dollar", triggerType);
    },
    flatpriceGoalCottonSpot(price: number, flatprice: number, basis: number, triggerType='MARGIN_LIMIT'): string {
      const calc = Calculations.saleTriggerFlatPriceCottonSPOT(
        price,
        flatprice,
        basis,
        this.stateCalculationsSpot.dollar,
        this.stateCalculationsSpot.constants,
        this.stateCalculationsSpot.logistic
      );
      return this.formatTriggerReturn(calc, "cents", triggerType);
    },
    flatpriceGoalSpotB3(price: number, flatprice: number, basis: number, triggerType='MARGIN_LIMIT'): string {
      const calc = Calculations.saleTriggerFlatPriceSPOTB3(
        price,
        flatprice,
        basis
      );
      return this.formatTriggerReturn(calc, 'real', triggerType);
    },
    exchangeGoalCottonSpot(price: number, flatprice: number, triggerType='MARGIN_LIMIT'): string {
      const calc = Calculations.saleTriggerDollarCottonSPOT(
        price,
        flatprice,
        this.stateCalculationsSpot.dollar,
        this.stateCalculationsSpot.constants,
        this.stateCalculationsSpot.logistic
      );
      return this.formatTriggerReturn(calc, 'real', triggerType);
    },
    exchangeGoalSpot(price: number, flatprice: number, pricing: string, triggerType='MARGIN_LIMIT'): string {
      const calc = Calculations.saleTriggerDollarSPOT(
        price,
        flatprice,
        this.stateCalculationsSpot.dollar,
        this.stateCalculationsSpot.constants,
        this.stateCalculationsSpot.logistic,
        pricing
      );
      return this.formatTriggerReturn(calc, 'real', triggerType);
    },
  },
});
