import { isEmptyObject, stringToNumber } from "@/utilsObject";

export interface IDataAsset {
  date: string;
  value: number;
}

export class DataAssetDTO implements IDataAsset {
  date = "";
  value = 0;

  constructor(dto?: IDataAsset) {
    if (dto && !isEmptyObject(dto)) {
      this.date = dto.date;
      this.value = stringToNumber(dto.value);
    }
  }
}

export class DataAsset extends DataAssetDTO {
  constructor();
  constructor(data: IDataAsset);
  constructor(data?: IDataAsset) {
    super(data);
  }
  /* getters */
  get dateWithoutGMT(): string {
    return this.date.replace(" GMT", "");
  }
  get valueBy100(): number {
    return this.value / 100;
  }
}

export interface IHistoryData {
  basis?: Array<DataAsset>;
  culture: Array<DataAsset>;
  currency?: Array<DataAsset>;
  code_basis?: Array<string>;
  code_culture: Array<string>;
  code_currency?: Array<string>;
}

export class HistoryDataDTO implements IHistoryData {
  basis: Array<DataAsset> | undefined = undefined;
  culture: Array<DataAsset> = [];
  currency: Array<DataAsset> | undefined = undefined;
  code_basis: Array<string> | undefined = undefined;
  code_culture: Array<string> = [];
  code_currency: Array<string> | undefined = undefined;

  constructor(dto?: IHistoryData) {
    if (dto && !isEmptyObject(dto)) {
      this.basis = dto?.basis?.map((el) => new DataAsset(el));
      this.culture = dto.culture.map((el) => new DataAsset(el));
      this.currency = dto?.currency?.map((el) => new DataAsset(el));
      this.code_basis = dto?.code_basis;
      this.code_culture = dto.code_culture;
      this.code_currency = dto?.code_currency;
    }
  }
}

export class HistoryData extends HistoryDataDTO {
  constructor();
  constructor(historyData: IHistoryData);
  constructor(historyData?: IHistoryData) {
    super(historyData);
  }
}

export interface IHistoryOwnershipData {
  id: number;
  name: string;
  data: HistoryData;
  cattleLotId?: number;
  cattleLotName?: string;
  pricing?: string;
}

export class HistoryOwnershipDataDTO implements IHistoryOwnershipData {
  id = 0;
  name = "";
  cattleLotId: number | undefined = undefined;
  cattleLotName: string | undefined = undefined;
  data = new HistoryData();

  constructor(dto?: IHistoryOwnershipData) {
    if (dto && !isEmptyObject(dto)) {
      this.id = dto.id;
      this.name = dto.name;
      this.data = new HistoryData(dto.data);
      this.cattleLotId = dto?.cattleLotId;
      this.cattleLotName = dto?.cattleLotName;
    }
  }
}

export class HistoryOwnershipData extends HistoryOwnershipDataDTO {
  constructor();
  constructor(historyOwnership: IHistoryOwnershipData);
  constructor(historyOwnership?: IHistoryOwnershipData) {
    super(historyOwnership);
  }
}

export interface IHistoryGrowingData {
  actual?: HistoryOwnershipData;
  future?: HistoryOwnershipData;
  spot?: HistoryOwnershipData;
  lot?: HistoryOwnershipData;
}

export class HistoryGrowingDataDTO implements IHistoryGrowingData {
  actual: HistoryOwnershipData | undefined = undefined;
  future: HistoryOwnershipData | undefined = undefined;
  spot: HistoryOwnershipData | undefined = undefined;
  lot: HistoryOwnershipData | undefined = undefined;

  constructor(dto?: IHistoryGrowingData) {
    if (dto && !isEmptyObject(dto)) {
      if (dto.actual)
        this.actual = new HistoryOwnershipData(dto.actual);
      if (dto.future)
        this.future = new HistoryOwnershipData(dto.future);
      if (dto.spot)
        this.spot = new HistoryOwnershipData(dto.spot);
      if (dto.lot)
        this.lot = new HistoryOwnershipData(dto.lot);
    }
  }
}

export class HistoryGrowingData extends HistoryGrowingDataDTO {
  constructor();
  constructor(historyGrowingData: IHistoryGrowingData);
  constructor(historyGrowingData?: IHistoryGrowingData) {
    super(historyGrowingData);
  }
}

export interface IHistoryProfit {
  soybean?: HistoryGrowingData;
  corn?: HistoryGrowingData;
  cotton?: HistoryGrowingData;
  cattle?: HistoryGrowingData;
}

export class HistoryProfitDTO implements IHistoryProfit {
  soybean: HistoryGrowingData | undefined = undefined;
  corn: HistoryGrowingData | undefined = undefined;
  cotton: HistoryGrowingData | undefined = undefined;
  cattle: HistoryGrowingData | undefined = undefined;

  constructor(dto?: IHistoryProfit) {
    if (dto && !isEmptyObject(dto)) {
      if (dto.soybean)
        this.soybean = new HistoryGrowingData(dto.soybean);
      if (dto.corn)
        this.corn = new HistoryGrowingData(dto.corn);
      if (dto.cotton)
        this.cotton = new HistoryGrowingData(dto.cotton);
      if (dto.cattle)
        this.cattle = new HistoryGrowingData(dto.cattle);
    }
  }
}

export class HistoryProfit extends HistoryProfitDTO {
  constructor();
  constructor(historyProfit: IHistoryProfit);
  constructor(historyProfit?: IHistoryProfit) {
    super(historyProfit);
  }
}

export interface ISaleTriggerGraph {
  yAxis: number;
  triggerType: string;
}

export interface IHistoryGraphOptions {
  title?: {
    text?: string;
    textAlign?: string;
    left?: string;
  },
  color?: any,
  tooltip?: {
    trigger?: string;
    axisPointer?: {
      type?: string;
    },
  },
  legend?: {
    data?: string[],
    top?: string
  },
  axisPointer?: {
    link?: {
      xAxisIndex?: string,
    },
    label?: {
      backgroundColor?: string,
    },
  },
  grid?: [
    {
      left?: string,
      right?: string,
      bottom?: string,
      top?: string,
      height?: string,
    },
  ],
  xAxis?: {
    data?: string[]
  },
  yAxis?: {
    type?: string;
    splitLine?: {
      show?: boolean
    },
    axisLabel?: {
      formatter: (arg: string) => string,
      fontSize?: number;
      textStyle?: {
        color?: string
      }
    }
    scale?: boolean;
    max?: number;
    min?: number;
    nameLocation?: string,
    nameGap?: number,
    nameTextStyle?: {
      color?: string,
      fontWeight?: string,
    },
    axisLine?: {
      show?: boolean,
      lineStyle?: {
        color?: string,
      },
    },
    axisTick?: {
      interval?: number,
      textStyle?: {
        color?: string,
      },
    },
    splitNumber?: number;
    boundaryGap?: number[];
  },
  toolbox?: {
    show?: boolean;
    right?: string;
    feature?: {
      magicType?: {
        show?: boolean;
        type?: string[];
        title?: {
          line?: string;
          bar?: string;
        },
        iconStyle?: {
          borderWidth?: number;
        }
      },
      restore?: {
        show?: boolean;
        title?: string;
      },
      saveAsImage?: {
        show?: boolean;
        title?: string;
      },
    }
  },
  dataZoom?: {
    start?: number;
    end?: number;
    type?: string;
    handleIcon?: string;
    handleSize?: string;
    handleStyle?: {
      color?: string;
      shadowBlur?: number;
      shadowColor?: string;
      shadowOffsetX?: number;
      shadowOffsetY?: number;
    }
  }[],
  series?: {
    name?: string;
    type?: string;
    data?: number[];
    areaStyle?: {
      color?: string;
    }
    smooth?: boolean;
    markLine?: {
      data?: ISaleTriggerGraph[] | undefined,
      lineStyle?: {
        normal?: {
          color?: string;
          width?: number;
        }
      }
    }
  }[],
  animationDuration?: number;
}