
import Vue from "vue";

import { Constants } from "@/constants";
import Business from "@/utilsBusiness";
import OwnershipForm from "@/components/crud/forms/OwnershipForm.vue";
import User from "@/models/user";
import Ownership from "@/models/ownership";
import CattleLot from "@/models/cattleLot";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";

// Components
import StockExchange from "@/components/profit/StockExchangeMarquee.vue";
import OwnershipCosts from "@/components/profit/OwnershipCosts.vue";
import Logistic from "@/components/profit/Logistic.vue";
import Pricing from "@/components/profit/Pricing.vue";
import MenuPricing from "@/components/profit/MenuPricing.vue";
import CardPricing from "@/components/profit/CardPricing.vue";
import AnonymousWizard from "@/components/profit/anonymous/AnonymousWizard.vue";
import NewAccountDialog from "@/components/profit/anonymous/NewAccountDialog.vue";
// Boi
import CattlePrice from "@/components/profit/CattlePrice.vue";
import SpotPricing from "@/components/profit/SpotPricing.vue";

import SaleTriggers from "@/components/profit/sale_triggers/SaleTriggers.vue";
import OperatingIncomes from "@/components/profit/OperatingIncomes.vue";
import OpsMargin from "@/components/profit/OpsMargin.vue";

import MatrixProfit from "@/components/profit/charts/MatrixProfit.vue";
import HistoryProfit, {
  IHistoryProfitComp,
} from "@/components/profit/charts/HistoryProfit.vue";
import FirstAccess, { IFirstAccessComp } from "./FirstAccess.vue";
import ConfigFirstProfit from "./ConfigFirstProfit.vue";

import { Profit } from "@/business/Profit";
import {
  IPayloadProfitCattleData,
  IPayloadProfitGrainData,
  IPayloadSimpleProfitCattleData,
  IPayloadSimpleProfitGrainData,
} from "@/store/modules/profit";
import { IFormFuncs } from "@/types/form-types";
import { convertDateToISO, numberToMonthName } from "@/utilsDate";
import { SimpleProfit } from "@/business/SimpleProfit";
import {
  IGrowingThemeType,
  IOrganizationThemeType,
  organizationThemes,
} from "@/plugins/theme/agrinvest";
import { mapGetters, mapActions } from "vuex"; // mapState
import { LOAD_GROWINGS } from "@/store/actions-types";
import { LOAD_HARVESTS } from "@/store/actions-types";
import {
  arrayJsonToClass,
  fakeOwnership,
  globalOwnershipClass,
} from "@/utilsObject";
import { profitService } from "@/services/profit";
import ProfitN1 from "@/models/profitN1";
import { LotSimpleProfit } from "@/business/CattleSimpleProfit";
import { IResourcesProfitAnonymous } from "@/types/profit-types";

export interface ISaleTriggersComp {
  prepareTriggers?: () => void;
  searchSaleTriggers?: () => void;
  reCalcMargin?: () => void;
}

export interface IMatrixComp {
  buildTable?: () => void;
}

export interface IPayMininalReload {
  b3_costs?: number;
  basis_fit?: number;
  bonus?: number;
  cattleAmount?: number;
  cattleTotalCost?: number;
  dollar: number;
  se: number;
  basis: number;
  productivity?: number;
  expectedProductivity: number;
  productionCost: number;
  fobbings: number;
  logistic: number;
  flatprice: number;
  constants: Constants;
}

export default Vue.extend({
  components: {
    OwnerCosts: OwnershipCosts,
    SaleTrig: SaleTriggers,
    StockExchange: StockExchange,
    Logis: Logistic,
    Pricing: Pricing,
    OpIncomes: OperatingIncomes,
    OpsMargin: OpsMargin,
    MaxProf: MatrixProfit,
    HisProf: HistoryProfit,
    Wizard: AnonymousWizard,
    NewAccount: NewAccountDialog,
    OwnershipForm: OwnershipForm,
    CattlePrice: CattlePrice,
    MenuPricing: MenuPricing,
    CardPricing: CardPricing,
    SpotPricing: SpotPricing,
    FirstAccess: FirstAccess,
    ConfigFirstProfit: ConfigFirstProfit,
  },
  data() {
    return {
      // Cliente selecionado
      // para ver a rentabilidade
      client: undefined as User | undefined,
      // Lista de fazenda
      ownerships: [] as Ownership[],
      // Lista de fazenda
      // para o boi sem a Global
      ownershipsCattle: [] as Ownership[],
      // Lista de Lotes do boi
      cattleLots: [] as CattleLot[],
      // Lotes filtrados por fazenda
      cattleLotsFiltered: [] as CattleLot[],
      // Ownership selecionada
      ownershipSelected: undefined as Ownership | undefined,
      // Safra selecionada
      harvestSelected: undefined as Harvest | undefined,
      // Cultura selecionada
      growingSelected: undefined as Growing | undefined,
      // Lote do boi selecionado
      cattleLotSelected: undefined as CattleLot | undefined,
      // API result
      profit: new Profit(),
      // API result
      simpleProfit: new SimpleProfit(),
      // nome da safra atual
      actualHarvest: "",
      // nome da safra futura
      futureHarvest: "",
      // busca do profit
      loading: false,
      // mostra btn para voltar ao topo
      btnTopShow: false,
      // mostra skelleton
      // antes do carregamento
      showSkelleton: false,
      // options do skelletion
      crudSkelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
      // flag para carregar o admin como N1
      adminAsN1: false,
      // Controla form Ownership
      dialogOwnershipForm: false,
      // FAB btn
      fab: false,

      // Conf AgroAmazonia
      // Loda da esquerda
      logoLeft: undefined as string | undefined,
      // Logo da direita
      logoRight: undefined as string | undefined,
      // Organização do user
      organization: "agrinvest" as keyof IOrganizationThemeType,
      // tema de cores
      theme: "agrinvest",
    };
  },
  async mounted() {
    this.showSkelleton = true;
    if (this.user.userRole === "admin") {
      if (this.$route.params.adminAsN1) {
        this.adminAsN1 = (this.$route.params.adminAsN1 === 'true');
      }
      const paramClient = this.$store.getters.getClient;
      if (paramClient) {
        this.client = paramClient;
      }
    }
    try {
      if (this.user.isAnonymous) {
        this.initLoadAnonymous();
      } else {
        if (this.mountedAsN1) {
          // load the ownership or the first admin omwership
          await this.loadOwnership();
        } else {
          // load all ownerships from user or client
          await this.loadOwnerships();
          this.loadCattleLots();
        }
        this.loadCattleBasis();
        if (this.harvests && this.growings) {
          this.initLoad();
        } else {
          await this.$store.dispatch(LOAD_GROWINGS);
          await this.$store.dispatch(LOAD_HARVESTS);
          this.initLoad();
        }
      }
    } catch (error) {
      console.error(error);
      this.$alertError("Erro ao carregar os dados do servidor");
      setTimeout(() => {
        this.$alertError("Desculpe, tente novamente mais tarde");
      }, 2000);
    }
  },
  methods: {
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
      updateCalculationsSoyCorn: "UPDATE_STATE_SOY_CORN",
      updateCalculationsCotton: "UPDATE_STATE_COTTON",
      updateCalculationsCattle: "UPDATE_STATE_CATTLE",
      updateCalculationsSpot: "UPDATE_STATE_SPOT",
      updateAll: "UPDATE_ALL",
      updateAllSpot: "UPDATE_ALL_SPOT",
      updateProfitGrowing: "UPDATE_PROFIT_GROWING",
      updateProfitHarvest: "UPDATE_PROFIT_HARVEST",
      updateProfitOwnershipSelected: "UPDATE_PROFIT_OWNERSHIP_SELECTED",
      updateProfitIncomeSelected: "UPDATE_PROFIT_INCOME_SELECTED",
      updateProfitMarginSelected: "UPDATE_PROFIT_MARGIN_SELECTED",
      updateProfitCattleLot: "UPDATE_PROFIT_CATTLE_LOT",
      updateQuotations: "UPDATE_PRO_QUOTATIONS",
      updateOwnership: "UPDATE_PRO_OWNERSHIP",
      updateHarvests: "UPDATE_PRO_HARVESTS",
      updateGrowings: "UPDATE_PRO_GROWINGS",
      updateFields: "UPDATE_PRO_FIELDS",
      updateIncomes: "UPDATE_PRO_INCOMES",
      getResource: "SIMPLE_SEARCH",
      loadCattleBasis: "LOAD_BEEF_CATTLE_BASIS",
      updateClient: "UPDATE_CLIENT",
      searchValidLots: "OWNERSHIP_VALID_CATTLE_LOT",
      searchOwnershipsClient: "OWNERSHIPS_CLIENT_PROFIT",
      loadProfitGrainStore: "LOAD_PROFIT_GRAIN_STORE",
      loadSimpleProfitGrainStore: "LOAD_SIMPLE_PROFIT_GRAIN_STORE",
      loadProfitGrainSpotStore: "LOAD_PROFIT_GRAIN_SPOT_STORE",
      loadSimpleProfitGrainSpotStore: "LOAD_SIMPLE_PROFIT_GRAIN_SPOT_STORE",
      loadProfitCattleStore: "LOAD_PROFIT_CATTLE_STORE",
      loadSimpleProfitCattleStore: "LOAD_SIMPLE_PROFIT_CATTLE_STORE",
      updatePricing: "UPDATE_PROFIT_PRICING",
    }),
    /**Anonymous functions */
    intanceOfResourceAnonymous(
      data: SimpleProfit | IResourcesProfitAnonymous
    ): data is IResourcesProfitAnonymous {
      return "growings" in data && "harvests" in data;
    },
    async initLoadAnonymous(): Promise<void> {
      this.showSkelleton = true;
      this.loading = true;
      const response = await profitService.anonymounsProfit();
      if (this.intanceOfResourceAnonymous(response)) {
        this.$store.commit(
          "setGrowings",
          arrayJsonToClass(Growing, response.growings)
        );
        this.$store.commit(
          "setHarvests",
          arrayJsonToClass(Harvest, response.harvests)
        );
        this.selectHarvestGrowing();
        // set fake Ownership
        this.ownershipSelected = fakeOwnership();
      }
      this.showSkelleton = false;
      this.loading = false;
      this.$store.dispatch('LOAD_EMPTY_ANONYMOUNS_PROFIT');
      this.$nextTick(() => {
        const wizard = this.$refs["wizard"] as IFormFuncs<ProfitN1>;
        if (wizard && "dialog" in wizard) wizard.dialog = true;
      });
    },
    async reloadFromAnonymous(profitN1: ProfitN1): Promise<void> {
      this.loading = true;
      const response = await profitService.anonymounsProfit(profitN1);
      this.loading = false;
      if (response instanceof SimpleProfit) {
        this.simpleProfit = response;
        if (
          this.ownershipSelected &&
          this.growingSelected &&
          this.harvestSelected
        ) {
          const payload: IPayloadSimpleProfitGrainData = {
            profit: this.simpleProfit,
            growing: this.growingSelected,
            harvest: this.harvestSelected,
          };
          await this.loadSimpleProfitGrainStore(payload);
          await this.updateCalculationsSoyCorn(this.growingSelected);
          this.callComponentsFuncs();
        }
      }
    },
    anonymousSaveProfit(): void {
      const newAccount = this.$refs["newAccount"] as IFormFuncs<never>;
      if (newAccount && "dialog" in newAccount) newAccount.dialog = true;
    },
    /**N2 e N3 functions */
    selectHarvestGrowing(): void {
      // Nomeia as safras
      this.actualHarvest = this.harvests.find(
        (h: Harvest) => h.is_current
      ).name;
      this.futureHarvest = this.harvests.find((h: Harvest) => h.is_future).name;
      // Marca a safra e cultura selecionada
      this.harvestSelected = this.harvests.find(
        (h: Harvest) => h.is_current === true
      );

      //FIXME: AFTER CHANGE USER GROUP FIX CONDITION
      this.organization =
        this.user.organization === "agroamazonia"
          ? "agroamazonia"
          : "agrinvest";

      if (this.organization === "agroamazonia") {
        this.growingSelected = this.growings.find(
          (g: Growing) => g.symbol === "BC"
        );
        this.setTheme("BC");
        this.setLogos();
      } else {
        this.growingSelected = this.growings.find(
          (g: Growing) => g.symbol === "S"
        );
        this.setTheme("S");
      }
    },
    initLoad(): void {
      this.selectHarvestGrowing();
      this.showSkelleton = false;
      // Inicia o carregamento de dados
      if (this.mountedAsN1) this.searchSimpleProfit();
      else this.searchProfit();
    },
    async loadOwnerships(): Promise<void> {
      try {
        // Se tem cliente selecionado
        // pega a propriedade do cliente
        this.ownerships = await this.searchOwnershipsClient(this.client);
        if (this.ownerships.length > 0) {
          // Outro array de prop para o boi
          // o boi não tem Global
          this.ownershipsCattle = [...this.ownerships];
          this.ownershipSelected = globalOwnershipClass();
          this.ownerships.push(this.ownershipSelected);
        }
      } catch (error) {
        console.error(error);
        this.$alertError("Erro ao carregar as propriedades");
      }
    },
    async loadOwnership(): Promise<void> {
      try {
        // Se tem cliente selecionado
        // pega a propriedade do cliente
        const result = await this.searchOwnershipsClient(this.client);
        if (result.length > 0) {
          this.ownershipSelected = result[0];
        }
      } catch (error) {
        console.error(error);
        this.$alertError("Erro ao carregar a propriedade");
      }
    },
    async loadFromNewOwnership(): Promise<void> {
      await this.loadOwnership();
      this.openConfiguration();
    },
    async loadCattleLots(): Promise<void> {
      try {
        const response = await this.searchValidLots(this.ownershipSelected?.id);
        if (response) this.cattleLots = response;
      } catch (error) {
        console.error(error);
        this.$alertError("Erro ao carregar os lotes para o boi");
      }
    },
    async searchProfit(): Promise<void> {
      this.loading = true;
      this.profit = await profitService.getProfit(this.client?.id);
      await this.reloadProfit();
      this.loading = false;
    },
    async reloadProfit(): Promise<void> {
      if (this.user.is_first_access) {
        this.ownershipSelected = fakeOwnership();
      }
      // payload com todas as informacoes do profit
      // atualiza todos de uma vez
      if (this.growingSelected?.isCattle) {
        // se a global esta selecionada
        // muda para primeira fazenda
        if (this.ownershipSelected?.id === 0)
          this.ownershipSelected = this.ownershipsCattle[0];
        // verifica se existe lote na fazenda
        if (this.cattleLots.length > 0) {
          this.cattleLotsFiltered = this.cattleLots.filter(
            (cl) => cl.ownership_id === this.ownershipSelected?.id
          );
        }
        // verifica se o lote selecionado
        // é da fazenda selecionada
        if (
          !this.cattleLotSelected ||
          this.cattleLotsFiltered.findIndex(
            (cl) => cl.id === this.cattleLotSelected?.id
          ) === -1
        ) {
          // senão, seleciona o primeiro lote da fazenda
          this.cattleLotSelected = this.cattleLotsFiltered[0];
        }
        if (this.ownershipSelected && this.cattleLotSelected) {
          const payload: IPayloadProfitCattleData = {
            profit: this.profit,
            ownership: this.ownershipSelected,
            lot: this.cattleLotSelected,
          };
          await this.loadProfitCattleStore(payload);
          await this.updateCalculationsCattle();
        }
      } else {
        if (
          this.ownershipSelected &&
          this.growingSelected &&
          this.harvestSelected
        ) {
          const payload: IPayloadProfitGrainData = {
            profit: this.profit,
            growing: this.growingSelected,
            harvest: this.harvestSelected,
            ownership: this.ownershipSelected,
          };
          if (this.harvestSelected.isSpot) {
            await this.loadProfitGrainSpotStore(payload);
            await this.updateCalculationsSpot({
              ...this.pricing,
              ownership: payload.ownership,
              growingSelected: this.growingSelected,
              constants: Business.getConstants(this.growingSelected.symbol),
            });
          } else {
            await this.loadProfitGrainStore(payload);
            if(this.growingSelected.isCotton) {
              await this.updateCalculationsCotton();
            }else {
              await this.updateCalculationsSoyCorn(this.growingSelected);
            }
          }
          // Set o maior preço
          // e faz correção de precificação
          // dependendo da cultura
          this.priceCorrection();
          // Set na store valores de fácil acesso
          // com base na cultura e precificação
          this.setEasyPriceAccess();
        }
      }
      if (this.user.is_first_access) {
        // Se é o primeiro acesso
        // mostro o dialog de config
        const firstAccessComp = this.$refs["firstAccess"] as IFirstAccessComp;
        if (firstAccessComp) {
          this.ownershipSelected = fakeOwnership();
          firstAccessComp.dialog = true;
          return;
        }
      }
      if(this.showWarning) return;
      this.callComponentsFuncs();
    },
    async reload(): Promise<void> {
      if (this.mountedAsN1) await this.reloadSimpleProfit();
      else await this.reloadProfit();
    },
    callComponentsFuncs(): void {
      const saleTriggerComp = this.$refs["saleTrig"] as ISaleTriggersComp;
      if (saleTriggerComp && saleTriggerComp.prepareTriggers)
        saleTriggerComp.prepareTriggers();
      const matrixComp = this.$refs["maxProf"] as IMatrixComp;
      if (matrixComp && matrixComp.buildTable) matrixComp.buildTable();
      const matrixCompCotton = this.$refs["maxProfCotton"] as IMatrixComp;
      if (matrixCompCotton && matrixCompCotton.buildTable)
        matrixCompCotton.buildTable();
    },
    async minimalReloadProfit(loadGraph = false): Promise<void> {
      if (this.growingSelected?.isCattle) {
        this.updateCalculationsCattle();
      }else if(this.growingSelected?.isCotton) {
        this.updateCalculationsCotton();
      }else {
        await this.updateCalculationsSoyCorn(this.growingSelected);
      }
      // Com os calculos definidos
      // reculcula os gatilhos e rebuild a matriz
      const saleTriggerComp = this.$refs["saleTrig"] as ISaleTriggersComp;
      if (saleTriggerComp && saleTriggerComp.reCalcMargin)
        saleTriggerComp.reCalcMargin();
      const matrixComp = this.$refs["maxProf"] as IMatrixComp;
      if (matrixComp && matrixComp.buildTable) matrixComp.buildTable();
      if (loadGraph) {
        this.loadedTrigger();
      }
    },
    configCalculations(): void {
      if (this.growingSelected) {
        if (this.growingSelected?.isCattle) {
          this.updateCalculationsCattle();
        }else if (this.growingSelected?.isCotton){
          this.updateCalculationsCotton();
        }else {
          this.updateCalculationsSoyCorn(this.growingSelected);
        }
      } else {
        console.warn("configCalculations: no growing select");
      }
    },
    setHarvest(_type: string): void {
      if (_type === "actual")
        this.harvestSelected = this.harvests.find((h: Harvest) => h.is_current);
      if (_type === "future")
        this.harvestSelected = this.harvests.find((h: Harvest) => h.is_future);
      if (_type === "spot") this.harvestSelected = this.spotHarvests;

      this.reload();
    },
    setGrowing(growing: string): void {
      this.growingSelected = this.growings.find(
        (g: Growing) => g.symbol === growing
      );
      this.setTheme(growing);
      this.setLogos();
      this.reload();
    },
    async reCalcOwnerCost(loadGraph = false): Promise<void> {
      if (this.growingSelected?.isCattle) {
        // Fluxo para o boi
        let profitLot = undefined;
        profitLot = Business.reCalcProfitCattleLots(
          this.profitCattleLotSelected
        );
        await this.updateProfitCattleLot(profitLot);
      } else {
        // fluxo para os grãos
        let profitOwnership = {};
        let profitIncome = {};
        let profitMargin = {};
        if (this.growingSelected?.isCotton) {
          profitOwnership = Business.reCalcOwnershipCostLogCotton(
            this.profitOwnershipSelected,
            this.stateCalculations.constants,
            this.stateCalculations.dollar
          );
          if (!this.mountedAsN1) {
            profitIncome = Business.reCalcOperacionalIncomeCotton(
              this.profitIncomeSelected,
              this.profitOwnershipSelected,
              this.stateCalculations.dollar
            );
            profitMargin = Business.reCalcOperacionalMarginCotton(
              this.profitMarginSelected,
              this.profitIncomeSelected,
              this.profitOwnershipSelected,
              this.stateCalculations.dollar
            );
          }
        } else {
          profitOwnership = Business.reCalcOwnershipCostLog(
            this.profitOwnershipSelected,
            this.stateCalculations.constants,
            this.stateCalculations.dollar
          );
          if (!this.mountedAsN1) {
            profitIncome = Business.reCalcOperacionalIncome(
              this.profitIncomeSelected,
              this.profitOwnershipSelected
            );
            profitMargin = Business.reCalcOperacionalMargin(
              this.profitMarginSelected,
              this.profitIncomeSelected,
              this.profitOwnershipSelected,
              this.stateCalculations.dollar
            );
          }
        }
        // Atualiza o estado
        await this.updateProfitOwnershipSelected(profitOwnership);
        await this.updateProfitIncomeSelected(profitIncome);
        await this.updateProfitMarginSelected(profitMargin);
        if(this.growingSelected) await this.updatePricing(this.growingSelected.symbol);
      }
      // Faz o reload dos componentes
      await this.minimalReloadProfit(loadGraph);
    },
    loadedTrigger(): void {
      const historyProfitComp = this.$refs["hisProf"] as IHistoryProfitComp;
      if (historyProfitComp && historyProfitComp.build) {
        if (this.mountedAsN1 && historyProfitComp.buildSimple)
          historyProfitComp.buildSimple();
        else historyProfitComp.build();
      }
    },
    findGrowingTheme(growingName: string): IGrowingThemeType {
      const growingTheme = organizationThemes[this.organization].find((el) => {
        return el.name === growingName;
      });
      if (growingTheme) return growingTheme;
      else return organizationThemes[this.organization][0];
    },
    setTheme(growing: string): void {
      switch (growing) {
        case "S":
          this.$vuetify.theme.themes.light =
            this.findGrowingTheme("soybean").light;
          this.$vuetify.theme.themes.dark =
            this.findGrowingTheme("soybean").dark;
          break;

        case "C":
          this.$vuetify.theme.themes.light =
            this.findGrowingTheme("corn").light;
          this.$vuetify.theme.themes.dark = this.findGrowingTheme("corn").dark;
          break;

        case "CT":
          this.$vuetify.theme.themes.light =
            this.findGrowingTheme("cotton").light;
          this.$vuetify.theme.themes.dark =
            this.findGrowingTheme("cotton").dark;
          break;

        case "BC":
          this.$vuetify.theme.themes.light =
            this.findGrowingTheme("cattle").light;
          this.$vuetify.theme.themes.dark =
            this.findGrowingTheme("cattle").dark;
          break;

        default:
          this.$vuetify.theme.themes.light =
            this.findGrowingTheme("soybean").light;
          this.$vuetify.theme.themes.dark =
            this.findGrowingTheme("soybean").dark;

          break;
      }
    },
    setLogos(): void {
      if (this.organization === "agroamazonia") {
        this.logoRight = require("../../../public/agroamazonia/logo_aa_w_v2.svg");
        if (this.growingSelected?.isCattle) {
          this.logoLeft = require("../../../public/agroamazonia/logo_lucraa_v1.svg");
        } else {
          this.logoLeft = require("../../../public/agroamazonia/logo_aa_w_v2.svg");
        }
      }
    },
    priceCorrection(): void {
      if (this.growingSelected?.isCotton) {
        // cotton sempre vai ser PPE
        this.$store.commit("setProfitPricingSelected", "ppe");
      } else if (this.growingSelected?.isCorn) {
        // milho
        // set o maior preço
        const price = Business.biggerPrice(
          this.pricing.cbot_ppe_exw_rs_sc,
          this.pricing.cbot_local_basis_exw_rs_sc,
          this.pricing.b3_local_basis_exw_rs_sc
        );
        if (price === "basisB3")
          this.$store.commit("setProfitPricingSelected", "basisB3");
        else if (price === "basisCbot")
          this.$store.commit("setProfitPricingSelected", "basisCbot");
        else this.$store.commit("setProfitPricingSelected", "ppe");
      } else {
        // soja
        // set o maior preço
        const price = Business.biggerPrice(
          this.pricing.cbot_ppe_exw_rs_sc,
          this.pricing.cbot_local_basis_exw_rs_sc
        );
        if (price === "basisCbot")
          this.$store.commit("setProfitPricingSelected", "basisCbot");
        else this.$store.commit("setProfitPricingSelected", "ppe");
      }
    },
    setEasyPriceAccess(reload = false): void {
      const prices = {
        priceRs: 0,
        priceUs: 0,
      };
      if (this.growingSelected?.isCotton) {
        prices.priceRs = this.pricing.nybot_ppe_exw_rs_arroba;
        prices.priceUs = this.pricing.nybot_ppe_exw_us_arroba;
      } else {
        if (this.princingSelected === "basisB3") {
          prices.priceRs = this.pricing.b3_local_basis_exw_rs_sc;
          prices.priceUs = this.pricing.b3_local_basis_exw_us_sc;
        } else if (this.princingSelected === "basisCbot") {
          prices.priceRs = this.pricing.cbot_local_basis_exw_rs_sc;
          prices.priceUs = this.pricing.cbot_local_basis_exw_us_sc;
        } else {
          prices.priceRs = this.pricing.cbot_ppe_exw_rs_sc;
          prices.priceUs = this.pricing.cbot_ppe_exw_us_sc;
        }
      }
      // Update store
      this.$store.commit("setProfitPrices", prices);
      if (reload) {
        const saleTriggerComp = this.$refs["saleTrig"] as ISaleTriggersComp;
        const historyProfitComp = this.$refs["hisProf"] as IHistoryProfitComp;
        const matrixComp = this.$refs["maxProf"] as IMatrixComp;
        const maxProfCotton = this.$refs["maxProfCotton"] as IMatrixComp;
        if (saleTriggerComp && saleTriggerComp.reCalcMargin)
          saleTriggerComp.reCalcMargin();
        if (matrixComp && matrixComp.buildTable) matrixComp.buildTable();
        if (maxProfCotton && maxProfCotton.buildTable)
          maxProfCotton.buildTable();
        if (historyProfitComp && historyProfitComp.build)
          historyProfitComp.build();
      }
    },
    onScroll(e: Event): void {
      if (typeof window === "undefined") return;
      const top =
        window.pageYOffset || (e.target as HTMLElement)?.scrollTop || 0;
      this.btnTopShow = top > 20;
    },
    toTop(): void {
      this.$vuetify.goTo(0);
    },
    async clearUserSelection(reload:string | undefined = undefined): Promise<void> {
      this.updateClient(null);
      this.client = undefined;

      if (reload) {
        this.loading = true;
        try {
          await this.loadOwnerships();
          this.loadCattleLots();
          this.loadCattleBasis();
          if (this.harvests && this.growings) {
            this.initLoad();
          } else {
            await this.$store.dispatch(LOAD_GROWINGS);
            await this.$store.dispatch(LOAD_HARVESTS);
            this.initLoad();
          }
        } catch (error) {
          console.error(error);
          this.$alertError("Erro ao carregar os dados do servidor");
          setTimeout(() => {
            this.$alertError("Desculpe, tente novamente mais tarde");
          }, 2000);
        }
      }
    },
    /**
     * N1 Functions -------------------
     */
    async searchSimpleProfit(): Promise<void> {
      this.loading = true;
      this.simpleProfit = await profitService.getSimpleProfit(this.client?.id);
      await this.reloadSimpleProfit();
      this.loading = false;
    },
    async reloadSimpleProfit(): Promise<void> {
      if (this.user.is_first_access) {
        // Se é o primeiro acesso
        // mostro o dialog de config
        const firstAccessComp = this.$refs["firstAccess"] as IFirstAccessComp;
        if (firstAccessComp) {
          this.ownershipSelected = fakeOwnership();
          firstAccessComp.dialog = true;
        }
      }
      // payload com todas as informacoes do profit
      // atualiza todos de uma vez
      if (this.growingSelected?.isCattle) {
        this.cattleLotSelected = new CattleLot();
        const lotProfit =
          this.simpleProfit.cattle?.lot || new LotSimpleProfit();
        this.cattleLotSelected.setLotInfoFromSimpleProfit(lotProfit);

        const payload: IPayloadSimpleProfitCattleData = {
          profit: this.simpleProfit,
        };
        await this.loadSimpleProfitCattleStore(payload);
        await this.updateCalculationsCattle();
      } else {
        if (
          this.ownershipSelected &&
          this.growingSelected &&
          this.harvestSelected
        ) {
          const payload: IPayloadSimpleProfitGrainData = {
            profit: this.simpleProfit,
            growing: this.growingSelected,
            harvest: this.harvestSelected,
          };
          if (this.harvestSelected.isSpot) {
            await this.loadSimpleProfitGrainSpotStore(payload);
            await this.updateCalculationsSpot({
              ...this.pricing,
              ownership: this.ownershipSelected,
              growingSelected: this.growingSelected,
              constants: Business.getConstants(this.growingSelected.symbol),
            });
          } else {
            await this.loadSimpleProfitGrainStore(payload);
            if(this.growingSelected.isCotton) {
              await this.updateCalculationsCotton();
            }else {
              await this.updateCalculationsSoyCorn(this.growingSelected);
            }
          }
          // Set o maior preço
          // e faz correção de precificação
          // dependendo da cultura
          this.priceCorrection();
          // Set na store valores de fácil acesso
          // com base na cultura e precificação
          this.setEasyPriceAccess();
        }
      }
      this.callComponentsFuncs();
    },
    convertMonthName(number: string): string {
      return numberToMonthName(number);
    },
    openOwnershipForm(): void {
      const form = this.$refs["formOwnership"] as IFormFuncs<Ownership>;
      if (form && "fromProfit" in form) {
        form.fromProfit = true;
        this.dialogOwnershipForm = true;
      }
    },
    updateOnwership(): void {
      const form = this.$refs["formOwnership"] as IFormFuncs<Ownership>;
      if (
        form &&
        "fromProfit" in form &&
        form.toUpdate &&
        this.ownershipSelected
      ) {
        form.fromProfit = true;
        form.toUpdate(this.ownershipSelected);
      } else {
        console.warn(
          "updateOnwership: no ref to form or no ownership selected"
        );
      }
    },
    openOwnershipFirstAccess(): void {
      if (
        this.ownershipSelected &&
        this.ownershipSelected.id &&
        this.ownershipSelected.id > 0
      )
        this.updateOwnership();
      else this.openOwnershipForm();
    },
    openConfiguration(): void {
      const form = this.$refs["configProfit"] as IFormFuncs<ProfitN1>;
      if (form && "dialog" in form) form.dialog = true;
    },
    async resetSimpleProfit(): Promise<void> {
      if (this.growingSelected?.isCattle) {
        // payload.ownership = this.returnEmptySimpleProfit();
        // payload.lot = payload.ownership.lot;
      } else {
        if (this.growingSelected && this.harvestSelected) {
          const payload: IPayloadSimpleProfitGrainData = {
            profit: new SimpleProfit(),
            growing: this.growingSelected,
            harvest: this.harvestSelected,
          };
          if (this.harvestSelected.isSpot) {
            await this.loadSimpleProfitGrainSpotStore(payload);
          } else {
            await this.loadSimpleProfitGrainStore(payload);
          }
        }
      }
      this.openConfiguration();
    },
    async saveProfit(): Promise<void> {
      const profitN1 = new ProfitN1();
      if (this.ownershipSelected && this.ownershipSelected.id) {
        if (this.growingSelected?.id) {
          if (this.growingSelected?.isCattle) {
            profitN1.animal_amount = this.profitCattleLotSelected.animal_amount;
            profitN1.yield_carcass = this.profitCattleLotSelected.yield_carcass;
            profitN1.production_cost =
              this.profitCattleLotSelected.arroba_total_cost;
            profitN1.final_weight_finishing =
              this.profitCattleLotSelected.slaughter_weight;
            profitN1.slaughter_date = convertDateToISO(
              this.profitCattleLotSelected.slaughter_date
            );
            // Validações
            if (profitN1.animal_amount === 0) {
              this.$alertInfo("Informe a quantidade de animais");
              return;
            }
            if (profitN1.yield_carcass === 0) {
              this.$alertInfo("Informe o rendimento da carcaça");
              return;
            }
            if (profitN1.final_weight_finishing === 0) {
              this.$alertInfo("Informe o peso de abate");
              return;
            }
            profitN1.setCattlePricings(this.profitCattleLotPriceSelected);
          } else {
            const port_tax = this.growingSelected?.isCotton
              ? "stuffing"
              : "fobbings";
            profitN1.production_cost = parseFloat(
              this.profitOwnershipSelected.production_cost
            );
            profitN1.productivity = parseInt(
              this.profitOwnershipSelected.productivity
            );
            profitN1.total_area = parseInt(
              this.profitOwnershipSelected.total_area
            );
            // Pega fobbings ou stuffing conforme a cultura
            profitN1.fobbings = this.profitOwnershipSelected[port_tax];
            // Por enquanto salva apenas inteiros
            // Manter compatibilidade
            profitN1.shipping = parseFloat(
              this.profitOwnershipSelected.logistic
            );
            if (profitN1.production_cost === 0) {
              this.$alertInfo("Informe o custo de produção");
              return;
            }
            if (profitN1.productivity === 0) {
              this.$alertInfo("Informe a produtividade");
              return;
            }
            if (profitN1.total_area === 0) {
              this.$alertInfo("Informe a área plantada");
              return;
            }
            if (profitN1.shipping === 0) {
              this.$alertInfo("Informe o frete");
              return;
            }
            if (profitN1.fobbings === 0) {
              if (this.growingSelected?.isCotton) {
                this.$alertInfo("Informe o valor da estufagem");
                return;
              } else {
                this.$alertInfo("Informe o fobbings");
                return;
              }
            }
            profitN1.harvest_id = this.harvestSelected?.id;
            profitN1.growing_id = this.growingSelected?.id;
            profitN1.user_id = this.user.id;
          }
          // incomes = Utils.pick(
          //   this.profitIncomeSelected,
          //   ['sold_amount', 'sold_rs_sc', 'sold_us_sc', 'sold_value_rs', 'sold_value_us']
          // )
          // incomes = {
          //   ...{
          //     sold_amount: 0,
          //     sold_rs_sc: 0,
          //     sold_us_sc: 0,
          //     sold_value_rs: 0,
          //     sold_value_us: 0,
          //   },
          // };
          // profit_n1 = Object.assign({}, profit_n1, incomes);
          // profit_n1.user_id = this.$store.getters.getLoggedUser.id;
          // profit_n1.ownership_id = this.ownershipSelected.id;
          // profit_n1.growing_id = this.growingSelected.id;
          profitN1.ownership_id = this.ownershipSelected.id;
          try {
            await profitService.saveSimpleProfit(profitN1);
            this.$alertSuccess("Cenário de rentabilidade salvo!");
            this.searchSimpleProfit();
          } catch (error) {
            this.$alertError("Houve um problema ao salvar o cenário");
          }
        } else {
          this.$alertInfo("Sem cultura, você selecionou a cultura?");
          console.warn("growingSelected: without id");
        }
      } else {
        this.$alertInfo("Cadastre a propriedade antes de salvar o cenário");
      }
    },
    updateLot(): void {
      const form = this.$refs["configProfit"] as IFormFuncs<ProfitN1>;
      if (form && form.updateLotFromProfit)
        form.updateLotFromProfit(
          this.profitCattleLotSelected,
          this.profitCattleLotPriceSelected
        );
    },
    formatterDate(value: string) {
      return this.$options.filters?.formatterBRZDate(value);
    }
  },
  computed: {
    ...mapGetters({
      growings: "getGrowings",
      harvests: "getHarvests",
      spotHarvests: "getSpotHarvests",
      profitOwnership: "getProfitOwnership",
      profitOwnershipSelected: "getProfitOwnershipSelected",
      profitIncomeSelected: "getProfitIncomeSelected",
      profitMarginSelected: "getProfitMarginSelected",
      profitCattleLotSelected: "getProfitCattleLotSelected",
      profitCattleLotPriceSelected: "getProfitCattleLotPriceSelected",
      quotations: "getProQuotations",
      incomes: "getProIncomes",
      fields: "getProFields",
      stateCalculations: "getCalculationState",
      user: "getLoggedUser",
      pricing: "getPrincing",
      princingSelected: "getPrincingSelected",
    }),
    showWarning(): boolean {
      if (this.growingSelected?.isCattle && this.profitCattleLotSelected)
        return this.profitCattleLotSelected?.emptyProfit;
      else if (this.profitOwnership) return this.profitOwnership?.emptyProfit;
      return false;
    },
    warningMessage(): string {
      if (this.mountedAsN1) {
        return "Dados da rentabilidade não encontrados, clique em novo e insera os dados, ao final salve sua rentabilidade";
      } else {
        if (this.growingSelected?.isCattle)
          return "Nenhum lote configurado para a cultura selecionada. Visite (<a href='/#/crud/cattle'>Cadastros > Lote</a>) para cadastrar as informações desta cultura";
        else
          return "Nenhum talhão configurado para a safra selecionada. Visite (<a href='/#/crud/field'>Cadastros > Talhões</a>) para cadastrar as informações desta safra";
      }
    },
    hideMatrixSPOT(): boolean {
      if (this.harvestSelected?.isSpot && this.growingSelected?.isCotton)
        return false;
      return true;
    },
    hidePricingSelector(): boolean {
      if (this.growingSelected?.isCotton) return true;
      if (this.growingSelected?.isCattle) return true;

      return false;
    },
    xlColumnSaleTrigger(): number {
      if (this.growingSelected?.isCotton && !this.hideMatrixSPOT)
        return 12;
      else
        return 7;
    },
    mountedAsN1(): boolean {
      return this.user.isN1 || this.adminAsN1 || this.user.isAnonymous;
    },
  },
  beforeDestroy() {
    if (this.user.organization === "agroamazonia") {
      this.setTheme("BC");
    } else {
      this.setTheme("S");
    }

    if (this.client) {
      this.clearUserSelection();
    }
  },
});
