
import Vue, { VueConstructor } from "vue";

import Calculations from "@/utilsBusiness";
import Growing from "@/models/growing";
import Harvest from "@/models/harvest";
import ProfitMixins from "@/mixins/profit";

import { mapGetters } from "vuex"; // mapState
import { toFixedNumber, toNumber } from "@/utilsNumber";
import { IMatrixGraphOptions } from "@/business/MatrixProfit";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof ProfitMixins>>
).extend({
  props: {
    growingSelected: Growing,
    harvestSelected: Harvest,
    loading: Boolean,
  },
  data() {
    return {
      // delay build matrix
      timeout: undefined as number | undefined,
      // tabs para seleção das unidades da matriz
      tabs: undefined as number | undefined,
      // define a unidade a ser mostrada na matriz
      formUnit: "_real",
      // chart pattern
      chartOptions: {} as IMatrixGraphOptions,
      xaxis: Array.from({ length: 7 }, () => 0) as string[] | number[],
      yaxis: Array.from({ length: 9 }, () => 0) as string[] | number[],
    };
  },
  /**From mixin:
   * - priceName
   * - pricingSelected
   */
  mixins: [ProfitMixins],
  computed: {
    ...mapGetters({
      stateCalculations: "getCalculationState",
      stateCalculationsSpot: "getCalculationStateSPOT",
      stateCalculationsCattle: "getCalculationStateCattle",
      cottonConfigs: "getCottonConfigs",
    }),
    productivityUnit(): string {
      if (this.growingSelected.isCotton) return "quilos";
      return "sacas";
    },
  },
  methods: {
    truncate(num: number, fixed: number): number {
      const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      const formated = num.toString().match(re);
      if (formated && formated.length > 0) return parseFloat(formated[0]);
      return num;
    },
    createRange(item: string, initValue: number, rounder = 2): string[] {
      let steps: number[] = [];
      let aux = 0;
      let auxInit = initValue;

      if (item === "xaxis") {
        const range = 3;
        let variation = 0;
        if (this.harvestSelected.is_current || this.harvestSelected.is_future) {
          if (this.growingSelected.symbol === "S") {
            variation = 2;
          } else if (this.growingSelected.symbol === "C") {
            if (this.pricingSelected === "basisB3") variation = 0.5;
            else variation = 5;
          } else if (this.growingSelected.symbol === "CT") {
            variation = 100;
          } else if (this.growingSelected.symbol === "BC") {
            variation = 0.5;
          }
        } else {
          if (this.pricingSelected === "basisB3") {
            variation = 0.5;
          } else {
            variation = 0.05;
          }
        }
        // after for = [-3, -2, -1]
        aux = 0;
        for (let i = range; i > 0; i--) {
          aux += variation;
          steps.push(
            (initValue - aux)
          );
        }
        // add initial value = [-3, -2, -1, 0]
        steps.push(initValue);
        // finish sequence = [-3, -2, -1, 0, 1, 2, 3]
        aux = 0;
        for (let i = 0; i < range; i++) {
          aux += variation;
          steps.push((initValue + aux));
        }
      }
      if (item === "yaxis") {
        const range = 4;
        let variation = this.growingSelected.isSoybean
          ? 0.15
          : this.growingSelected.isCorn
          ? 0.15
          : this.growingSelected.isCotton
          ? 0.75
          : this.growingSelected.isCattle
          ? 0.01
          : 0.15;

        if (
          this.harvestSelected.isSpot &&
          this.growingSelected.isCorn &&
          this.pricingSelected === "basisB3"
        ) {
          variation = 0.5;
        }
        // SE NÃO FOR BOI USA O FLATPRICE PRO EIXO-Y
        if (!this.growingSelected.isCattle) {
          // after for = [-4, -3, -2, -1]
          aux = 0;
          for (let i = range; i > 0; i--) {
            aux = aux + variation;
            steps.push((initValue - aux));
          }
          // add initial value = [-4, -3, -2, -1, 0]
          steps.push(initValue);
          // finish sequence = [-4, -3, -2, -1, 0, 1, 2, 3, 4]
          aux = 0;
          for (let i = 0; i < range; i++) {
            aux = aux + variation;
            steps.push((initValue + aux));
          }
          // SE FOR BOI USA BONUS (bonus += bonus * x)
        } else {
          aux = 0;
          for (let i = range; i > 0; i--) {
            aux = aux + variation;
            auxInit = initValue;

            auxInit += initValue * aux;
            steps.push(auxInit);
          }
          // add initial value = [-4, -3, -2, -1, 0]
          steps.push(initValue);
          // finish sequence = [-4, -3, -2, -1, 0, 1, 2, 3, 4]
          aux = 0;
          for (let i = 0; i < range; i++) {
            aux = aux + variation;
            auxInit = initValue;

            auxInit -= initValue * aux;
            steps.push(auxInit);
          }
        }
      }
      // retorna num de sacas/flatprice ordenadas
      steps = steps.sort((a, b) => a - b);
      return steps.map((element) => element.toFixed(rounder));
    },
    buildXaxis(): string[] | number[] {
      if (this.harvestSelected.isSpot && !this.growingSelected.isCattle) {
        let initValue = this.stateCalculationsSpot.dollar;

        if (this.pricingSelected === "basisB3") {
          initValue = this.stateCalculationsSpot.b3;
        }

        if (initValue === 0) {
          return Array.from({ length: 7 }, () => 0);
        } else {
          return this.createRange("xaxis", initValue);
        }
      } else {
        let initValue = 0;
        if(this.growingSelected.isCattle)
          initValue = this.stateCalculationsCattle.carcassProductivity || 0;
        else
          initValue = this.stateCalculations.productivity || 0;

        // se não tem produtividade retorna array com 0s
        if (initValue === 0) {
          return Array.from({ length: 7 }, () => 0);
        } else {
          return this.createRange("xaxis", initValue);
        }
      }
    },
    buildYaxis(): string[] | number[] {
      if (!this.growingSelected.isCattle) {
        let initValue: number | undefined = undefined;
        let rounder = 4;

        if (this.harvestSelected.isSpot && this.pricingSelected === "basisB3") {
          initValue = this.stateCalculationsSpot.basisExwB3;
          rounder = 2;
        } else {
          initValue = this.stateCalculationsSpot.flatprice || 0;
          if(this.pricingSelected === "basisB3") rounder = 2;
        }
        if (!initValue || initValue === 0) {
          return Array.from({ length: 9 }, () => 0);
        }

        return this.createRange("yaxis", initValue, rounder);
      } else {
        const initValue =
          this.stateCalculationsCattle.b3 + this.stateCalculationsCattle.basis;

        if (initValue === 0) {
          return Array.from({ length: 9 }, () => 0);
        }

        return this.createRange("yaxis", initValue, 2);
      }
    },
    buildSeries(): [number, number, number[][]] {
      const dataSeries: number[][] = [];
      const onlyValues = [];
      //  FIRST CHECK BOI GORDO - sem safra
      if (this.growingSelected.isCattle) {
        for (let i = 0; i < this.xaxis.length; i++) {
          for (let j = 0; j < this.yaxis.length; j++) {
            const value = Calculations.cattleRentability(
                toNumber(this.yaxis[j]),
                toNumber(this.xaxis[i]),
                this.formUnit
              );

            onlyValues.push(value);
            dataSeries.push([i, j, isNaN(value) ? 0 : value]);
          }
        }
      }
      // Se Constroi serie para MILHO E SOJA
      else if (this.harvestSelected.isSpot) {
        if (this.growingSelected.isCorn && this.pricingSelected === "basisB3") {
          for (let i = 0; i < this.xaxis.length; i++) {
            for (let j = 0; j < this.yaxis.length; j++) {
              const value = toFixedNumber(
                (toNumber(this.yaxis[j]) + toNumber(this.xaxis[i])),
                2
              );

              onlyValues.push(value);
              dataSeries.push([i, j, isNaN(value) ? 0 : value]);
            }
          }
        } else {
          for (let i = 0; i < this.xaxis.length; i++) {
            for (let j = 0; j < this.yaxis.length; j++) {
              const value = Calculations.spotPricing(
                  toNumber(this.yaxis[j]),
                  toNumber(this.xaxis[i]),
                  this.stateCalculationsSpot.logistic,
                  this.stateCalculationsSpot.constants,
                  this.growingSelected.symbol,
                  this.pricingSelected
              );

              onlyValues.push(value);
              dataSeries.push([i, j, isNaN(value) ? 0 : value]);
            }
          }
        }
      } else {
        if (this.growingSelected.isSoybean || this.growingSelected.isCorn) {
          for (let i = 0; i < this.xaxis.length; i++) {
            for (let j = 0; j < this.yaxis.length; j++) {
              let value = 0;
              if(this.pricingSelected === "basisB3") {
                value = Calculations.rentabilityB3(
                  toNumber(this.yaxis[j]),
                  toNumber(this.xaxis[i]),
                  this.formUnit
                );
              }else {
                value = Calculations.rentability(
                    toNumber(this.yaxis[j]),
                    toNumber(this.xaxis[i]),
                    this.formUnit
                );

              }

              onlyValues.push(value);
              dataSeries.push([i, j, isNaN(value) ? 0 : value]);
            }
          }
        } else if (this.growingSelected.isCotton) {
          for (let i = 0; i < this.xaxis.length; i++) {
            for (let j = 0; j < this.yaxis.length; j++) {
              const value = Calculations.rentability_cotton(
                  toNumber(this.yaxis[j]),
                  toNumber(this.xaxis[i]),
                  this.cottonConfigs,
                  this.formUnit
              );

              onlyValues.push(value);
              dataSeries.push([i, j, isNaN(value) ? 0 : value]);
            }
          }
        }
      }

      const minV = Math.min(...onlyValues);
      const maxV = Math.max(...onlyValues);

      return [minV, maxV, dataSeries];
    },
    buildChart() {
      this.xaxis = this.buildXaxis();
      this.yaxis = this.buildYaxis();

      let yaxisName = "U$/Bushel";
      let xaxisName = " sc";
      let chartTitle = "Produtividade (sacas)";
      let prefix = false;

      let [minV, maxV, dataSeries] = this.buildSeries();
      dataSeries = dataSeries.map((el) => {
        if (
          el[2] === null ||
          el[2] === undefined ||
          el[2] === Infinity ||
          el[2] === -Infinity
        ) {
          el[2] = 0;
        }
        return el;
      });

      if (this.growingSelected.isCattle) {
        yaxisName = "R$/@";
        xaxisName = "@";
        chartTitle = "Peso da Carcaça (@)";
      } else if (this.harvestSelected.isSpot) {
        if (this.growingSelected.isCotton) {
          yaxisName = "¢/lb";
        } else if (
          this.growingSelected.isCorn &&
          this.pricingSelected === "basisB3"
        ) {
          yaxisName = "R$/sc";
        } else {
          yaxisName = "U$/Bushel";
        }
        prefix = true;
        xaxisName = "R$";
        if (this.pricingSelected !== "basisB3") {
          chartTitle = "Taxa de Câmbio";
        } else {
          chartTitle = "Cenários de preços futuros B3";
        }
      } else {
        if (this.growingSelected.isCotton) {
          yaxisName = "¢/lb";
          xaxisName = "kg";
          chartTitle = "Produtividade (quilos)";
        } else {
          yaxisName = this.pricingSelected === "basisB3" ? "R$/sc" : "U$/Bushel";
          xaxisName = "sc";
          chartTitle = "Produtividade (sacas)";
        }
      }

      this.chartOptions = {
        title: {
          show: true,
          text: chartTitle,
          //SET TITLE AT CENTER
          left: "center",
        },
        left: "20%",
        tooltip: {
          trigger: "item",
          position: "bottom",
          axisPointer: {
            type: "cross",
          },
          formatter: (param: {value: number[]}): string => {
            if (this.formUnit === "_percent") {
              return `${param.value[2].toFixed(2)} %`;
            } else if (this.formUnit === "_dolar") {
              return `$ ${param.value[2].toFixed(2)}`;
            } else {
              return `R$ ${param.value[2].toFixed(2).replace(/\./g, ",")}`;
            }
          },
        },
        animation: true,
        grid: {
          show: true,
          left: "15%",
          right: "10%",
          top: "23%",
          bottom: "10%",
          width: "auto",
        },
        itemStyle: {
          borderWidth: 0.5,
          borderColor: "#ffff",
          borderType: "solid",
        },
        xAxis: {
          type: "category",
          data: this.xaxis,
          position: "top",
          nameLocation: "start",
          axisLabel: {
            formatter: (param: string): string => {
              if (prefix) {
                return `R$ ${param.replace(/\./g, ",")}`;
              } else {
                return `${param.replace(/\./g, ",")} ${xaxisName}`;
              }
            },
            fontWeight: "bold",
            fontSize: 12,
          },
          splitArea: {
            show: true,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: this.yaxis,
          name: yaxisName,
          nameLocation: "middle",
          nameGap: 80,
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontWeight: "bold",
          },
          splitArea: {
            show: true,
          },
          axisLabel: {
            formatter: (param: string): string => {
              // GRAOS FlatPrice ($)
              if (this.growingSelected.isCattle) {
                return `R$ ${param.replace(/\./g, ",")}`;
                // Boi B3 (R$)
              } else if (
                this.growingSelected.isCorn &&
                this.pricingSelected === "basisB3"
              ) {
                return `R$ ${param.replace(/\./g, ",")}`;
              } else {
                return `$ ${param}`;
              }
            },
            fontSize: 12,
            fontWeight: "bold",
          },
        },
        visualMap: {
          min: minV,
          max: maxV,
          calculable: true,
          orient: "horizontal",
          bottom: "10%",
          show: false,
          inRange: {
            color: ["#F8696B", "#FEE683", "#63BE7B"],
          },
        },
        series: [
          {
            type: "heatmap",
            data: dataSeries,
            label: {
              show: true,
              color: "#000000",
              formatter: (param: {value: number[]}): string => {
                if (this.$vuetify.breakpoint.smAndUp) {
                  if (this.formUnit === "_percent") {
                    return `${param.value[2].toFixed(2)} %`;
                  } else if (this.formUnit === "_dolar") {
                    return `$ ${param.value[2].toFixed(2)}`;
                  } else {
                    return `R$ ${param.value[2].toFixed(2).replace(/\./g, ",")}`;
                  }
                } else {
                  return param.value[2].toFixed(2);
                }
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "#00000080",
                borderColor: "#0000FF",
              },
            },
          },
          {
            type: "heatmap",
            data: [[3, 4, dataSeries[31][2]]],
            itemStyle: {
              borderColor: "#000000",
              borderWidth: 3,
            },
            label: {
              show: true,
              color: "#000000",
              formatter: (param: {value: number[]}): string => {
                if (this.$vuetify.breakpoint.smAndUp) {
                  if (this.formUnit === "_percent") {
                    return `${param.value[2].toFixed(2)} %`;
                  } else if (this.formUnit === "_dolar") {
                    return `$ ${param.value[2].toFixed(2)}`;
                  } else {
                    return `R$ ${param.value[2].toFixed(2).replace(/\./g, ",")}`;
                  }
                } else {
                  return param.value[2].toFixed(2);
                }
              },
            },
          },
        ],
        toolbox: {
          orient: "horizontal",
          x: "80%",
          feature: {
            saveAsImage: {
              show: true,
              title: "Salvar",
            },
          },
        },
      };
      return this.chartOptions;
    },
    buildTable(_formatter: string): void {
      if (_formatter) {
        this.formUnit = _formatter;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      if (this.growingSelected.isCattle) {
        this.formUnit = _formatter ? _formatter : "_arroba";
      } else {
        this.formUnit = _formatter ? _formatter : this.formUnit;
      }

      this.timeout = setTimeout(() => {
        this.chartOptions = this.buildChart();
      }, 300);
    },
  },
  watch: {
    growingSelected(val: Growing): void {
      if (val) {
        if (val.symbol === "BC") {
          this.tabs = 0;
          this.buildTable("_arroba");
        } else {
          this.tabs = 0;
          this.buildTable("_real");
        }
      }
    },
    harvestSelected(val: Harvest): void {
      if (val) {
        this.tabs = 0;
        if (this.growingSelected.symbol === "BC") {
          this.tabs = 0;
          this.buildTable("_arroba");
        } else {
          this.tabs = 0;
          this.buildTable("_real");
        }
      }
    },
  },
});
