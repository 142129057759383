
import Vue, { VueConstructor } from "vue";

import FormMixins from "@/mixins/form";
import Ownership from "@/models/ownership";
import { getMonth } from "@/utilsDate";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  mixins: [FormMixins],
  data() {
    return {
      ownership: new Ownership(),
      deal_soybean_reference: ["01", "03", "05"],
      deal_corn_reference: ["03", "09"],
      deal_cotton_reference: ["03", "12"],
      picker: new Date().toISOString().substr(0, 7),
      actualYear: undefined as string | undefined,
      // Indica que o form foi aberto no rentabilidade
      fromProfit: false,
    };
  },
  created() {
    this.harvestYear();
  },
  methods: {
    soybeanMonths(val: string): boolean {
      if (this.deal_soybean_reference.indexOf(val.split("-")[1]) !== -1)
        return true;

      return false;
    },
    cornMonths(val: string): boolean {
      if (this.deal_corn_reference.indexOf(val.split("-")[1]) !== -1)
        return true;

      return false;
    },
    cottonMonths(val: string): boolean {
      if (this.deal_cotton_reference.indexOf(val.split("-")[1]) !== -1)
        return true;

      return false;
    },
    async save(): Promise<void> {
      if (this.validate()) {
        this.ownership.user_id = this.user.id;
        this.ownership.deal_soybean_reference = getMonth(
          this.ownership.deal_soybean_reference
        );
        this.ownership.deal_corn_reference = getMonth(
          this.ownership.deal_corn_reference
        );
        this.ownership.deal_cotton_reference = getMonth(
          this.ownership.deal_cotton_reference
        );
        if (this.validateReference()) {
          try {
            const ownership = await crudService.saveClass(
              Ownership,
              Ownership.getResource(),
              this.ownership
            );
            this.$alertSuccess(this.componentsText.mSuccess);
            // Se a chamada veio da tela de rentabilidade
            // emit outro evento
            if (this.fromProfit) {
              this.$emit("loadOwnership");
            } else {
              this.$emit("updateResourceList", ownership);
            }
            this.close();
          } catch (error) {
            this.$alertError(this.componentsText.mError);
            console.error(error);
          }
        }
      }
    },
    initialize() {
      this.ownership = new Ownership();
    },
    validateReference() {
      if (
        !this.ownership?.deal_soybean_reference &&
        !this.ownership?.deal_corn_reference &&
        !this.ownership?.deal_cotton_reference
      ) {
        this.$alertError(
          "Selecione a referência de pelo menos uma cultura utilizada"
        );
        return false;
      }
      return true;
    },
    toUpdate(item: Ownership): void {
      this.show = true;
      this.$nextTick(() => {
        this.ownership = new Ownership(item);
      });
    },
    harvestYear(): void {
      // Atualiza o ano na virada
      // da safra
      const _date = new Date();

      if (_date.getMonth() > 9) {
        _date.setFullYear(_date.getFullYear() + 1);
        this.actualYear = _date.toISOString().substr(0, 4);
      } else {
        this.actualYear = _date.toISOString().substr(0, 4);
      }
    },
  },
  computed: {
    componentsText() {
      if (this.ownership && this.ownership.id) {
        return {
          action: "Atualizar",
          mSuccess: "Propriedade atualizada com sucesso!",
          mError: "Houve um problema ao atualizar a propriedade",
        };
      } else {
        return {
          action: "Adicionar",
          mSuccess: "Propriedade inserida com sucesso!",
          mError: "Houve um problema ao inserir a propriedade",
        };
      }
    },
  },
});
