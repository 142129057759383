
import Vue from "vue";

import Ownership from "@/models/ownership";
import CattleLot from "@/models/cattleLot";

import { mapGetters, mapMutations, mapActions } from "vuex"; // mapState
import { ISimpleTableItens } from "@/types/profit-types";
import { CattlePricing } from "@/business/Pricing";
import { isValidKeys, notNullObjectProperties } from "@/utilsObject";
import { crudService } from "@/services/crud";

export default Vue.extend({
  props: {
    ownershipSelected: Ownership,
    cattleLotSelected: CattleLot,
    loading: Boolean,
  },
  data() {
    return {
      inset: false,
      cattlePriceLot: {} as CattlePricing,
      priceHeader: [
        {
          text: "Preço Futuro na B3",
          currency: "R$/@",
          tooltip: "Valor da @ na bolsa B3",
          value: "b3_price",
          decimal: 4,
          contract: {
            text: "Contrato a termo",
            value: "is_forward_contract",
          },
        },
        {
          text: "Custo Operacional na B3",
          currency: "R$/@",
          tooltip: "Custo das aperações",
          value: "b3_costs",
          decimal: 2,
        },
        {
          text: "Região de Referência",
          currency: "",
          tooltip: "Região de referência para o basis local",
          value: "basis_region",
          decimal: 2,
          ref: true,
        },
        {
          text: "Basis Local",
          currency: "R$/@",
          tooltip: "Basis local da região de referência",
          value: "basis",
          decimal: 2,
        },
        {
          text: "Ajuste do Basis",
          currency: "R$/@",
          tooltip: "Ajuste para a propriedade",
          value: "basis_fit",
          decimal: 2,
        },
        {
          text: "Bonificação",
          currency: "R$/@",
          tooltip: "Bonificação do lote",
          value: "bonus",
          decimal: 2,
        },
        {
          text: "Referência do preço local",
          currency: "R$/@",
          tooltip: "Ajuste para a propriedade",
          value: "local_price_ref",
          decimal: 2,
        },
      ] as ISimpleTableItens[],
    };
  },
  methods: {
    ...mapMutations(["setResource"]),
    ...mapActions({
      saveResource: "SAVE_UPDATE",
    }),
    async save(): Promise<void> {
      if (this.isN1User) {
        this.$emit("saveProfit");
      } else {
        const price = new CattlePricing(this.profitCattleLotPriceSelected);
        notNullObjectProperties(price, ["id", "basis_region"]);
        try {
          price.cattle_lot_id = this.cattleLotSelected.id;
          price.ownership_id = this.ownershipSelected.id;
          price.local_price_ref = this.localPriceRef;
          await crudService.saveClass(
            CattlePricing,
            CattlePricing.getResource(),
            price
          );
          this.$alertSuccess("Precificação salva!");
          // Faz o reload
          // para pegar a nova precifecação
          this.$emit("searchProfit");
        } catch (error) {
          console.error(error);
          this.$alertError("Erro ao salvar precificação");
        }
      }
    },
    setCostValue(item: string, event: string): void {
      const num: number = parseFloat(event.toString().replace(/,/g, "."));
      if (num && !isNaN(num) && typeof num !== "undefined") {
        if (isValidKeys(CattlePricing, item)) this.cattlePriceLot[item] = num;
        else console.warn("setCostValue: invalid key to object CattlePricing");
      }
    },
    setBasisByRegion(): void {
      if (this.profitCattleLotPriceSelected.basis_region) {
        const region =
          this.cattleBasis[this.profitCattleLotPriceSelected.basis_region];
        const slaughter = this.$luxon(this.cattleLotSelected.slaughter_date);
        this.profitCattleLotPriceSelected.basis = region[slaughter.month];
      } else {
        this.profitCattleLotPriceSelected.basis = 0;
      }
    },
    rollBackSEPrice(): void {
      if (!this.profitCattleLotPriceSelected.is_forward_contract) {
        this.profitCattleLotPriceSelected.b3_price = this.stateCalculations.se;
      }
    },
  },
  computed: {
    ...mapGetters({
      profitCattleLotPriceSelected: "getProfitCattleLotPriceSelected",
      cattleBasisRegions: "getCattleBasisRegions",
      cattleBasis: "getCattleBasis",
      stateCalculations: "getCalculationState",
      quotations: "getProQuotations",
      user: "getLoggedUser",
    }),
    localPriceRef(): number {
      return (
        this.profitCattleLotPriceSelected.b3_price +
        (this.profitCattleLotPriceSelected.basis +
          this.profitCattleLotPriceSelected.basis_fit +
          this.profitCattleLotPriceSelected.bonus +
          this.profitCattleLotPriceSelected.b3_costs)
      );
    },
    isN1User(): boolean {
      if (this.user.userRole === "admin") {
        if (this.$route.params.adminAsN1) {
          const adminAsN1 = Boolean(this.$route.params.adminAsN1);
          return adminAsN1;
        }
      }
      return this.user.isN1;
    },
  },
});
