
import Vue from "vue";

import Growing from "@/models/growing";
import { mapGetters } from "vuex"; // mapState
import { ISimpleTableItens } from "@/types/profit-types";


export default Vue.extend({
  props: {
    growingSelected: {
      type: Growing,
      default: () => new Growing()
    },
    loading: {
      type: Boolean,
      default: false
    },
    priceVia: {
      type: String,
      default: 'ppe',
      validator: (value: string) => {
        return ["ppe", "basis"].indexOf(value) !== -1;
      },
    }
  },
  computed: {
    ...mapGetters({
      pricing: "getPrincing",
    }),
    tableItens(): ISimpleTableItens[] {
      if (this.growingSelected.isCotton) {
        return this.cottonItensPPE;
      }

      if(this.priceVia === 'basis') {
        return this.soyCornItensLocalBasis;
      }else if(this.priceVia === 'b3') {
        return this.cornB3ItensLocalBasis;
      }else {
        return this.soyCornItensPPE;
      }
    },
    title(): string {
      if(this.priceVia === 'basis') return 'Basis EXW';
      if(this.priceVia === 'b3') return 'B3';
      return 'PPE';
    },
    subtitle(): string {
      if(this.priceVia === 'basis') return 'Referência de Preço';
      if(this.priceVia === 'b3') return 'B3 + Basis EXW';
      return 'Preço de Paridade de Exportação';
    },
    icon():string {
      if(this.priceVia === 'basis' || this.priceVia === 'b3') return 'mdi-home-silo';
      return 'mdi-ferry';
    },
    cottonItensPPE(): ISimpleTableItens[] {
      return [
        {
          text: "Flat Price",
          currency: "¢/lb",
          tooltip: "NYBOT + Basis",
          value: "nybot_flat_price",
          bgcolor: "c4c4c4",
          decimal: 2,
        },
        {
          text: "Preço FCA",
          currency: "R$/@",
          tooltip: "Sobre Rodas",
          value: "nybot_ppe_fca_rs_arroba",
          decimal: 2,
        },
        {
          text: "Preço FCA",
          currency: "$/@",
          tooltip: "Sobre Rodas",
          value: "nybot_ppe_fca_us_arroba",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "R$/@",
          tooltip: "Fazenda",
          value: "nybot_ppe_exw_rs_arroba",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "$/@",
          tooltip: "Fazenda",
          value: "nybot_ppe_exw_us_arroba",
          decimal: 2,
        },
      ];
    },
    soyCornItensPPE(): ISimpleTableItens[] {
      return [
        {
          text: "Flat Price",
          currency: "$/Bushel",
          tooltip: "CBOT + Basis FOB",
          value: "cbot_flat_price_ppe",
          bgcolor: "c4c4c4",
          decimal: 4,
        },
        {
          text: "Preço FCA",
          currency: "R$/sc",
          tooltip: "Preço sobre rodas",
          value: "cbot_ppe_fca_rs_sc",
          decimal: 2,
        },
        {
          text: "Preço FCA",
          currency: "$/sc",
          tooltip: "Preço sobre rodas",
          value: "cbot_ppe_fca_us_sc",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "R$/sc",
          tooltip: "Preço na fazenda",
          value: "cbot_ppe_exw_rs_sc",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "$/sc",
          tooltip: "Preço na fazenda",
          value: "cbot_ppe_exw_us_sc",
          decimal: 2,
        },
      ];
    },
    soyCornItensLocalBasis(): ISimpleTableItens[] {
      return [
        {
          text: "Flat Price",
          currency: "$/Bushel",
          tooltip: "CBOT + Basis EXW",
          value: "cbot_flat_price_local_basis",
          bgcolor: "c4c4c4",
          decimal: 4,
        },
        {
          text: "Preço EXW",
          currency: "R$/sc",
          tooltip: "Preço na fazenda",
          value: "cbot_local_basis_exw_rs_sc",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "$/sc",
          tooltip: "Preço na fazenda",
          value: "cbot_local_basis_exw_us_sc",
          decimal: 2,
        },
      ];
    },
    cornB3ItensLocalBasis(): ISimpleTableItens[] {
      return [
        {
          text: "Flat Price",
          currency: "R$/sc",
          tooltip: "B3 + Basis EXW",
          value: "b3_flat_price_local_basis",
          bgcolor: "c4c4c4",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "R$/sc",
          tooltip: "Preço na fazenda",
          value: "b3_local_basis_exw_rs_sc",
          decimal: 2,
        },
        {
          text: "Preço EXW",
          currency: "$/sc",
          tooltip: "Preço na fazenda",
          value: "b3_local_basis_exw_us_sc",
          decimal: 2,
        },
      ];
    }
  },
  methods: {
    formatter(value: number, after: number): string {
      return this.$options.filters?.formatterUnit(value, after);
    }
  },
});
