
import Vue, { VueConstructor } from "vue";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import CattleLot from "@/models/cattleLot";
import User from "@/models/user";
import SaleTrigger, { SaleTriggerScreen } from "@/models/saleTrigger";

import SaleTriggersForm from "./SaleTriggersForm.vue";
import CattleDataTable from "./CattleDataTable.vue";
import CottonDataTable from "./CottonDataTable.vue";
import GrainDataTable from "./GrainDataTable.vue";
import Notification from "./Notification.vue";
import SaleTriggersMixins from "@/mixins/saleTriggers";

import { IParams } from "@/types/store-types";
import { IFormFuncs } from "@/types/form-types";
import { mapGetters } from "vuex";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof SaleTriggersMixins>>
).extend({
  props: {
    harvestSelected: Harvest,
    growingSelected: Growing,
    ownershipSelected: Ownership,
    cattleLotSelected: CattleLot,
    client: User,
    loading: Boolean,
  },
  components: {
    SaleTriggersForm,
    CattleDataTable,
    CottonDataTable,
    GrainDataTable,
    Notification
  },
  mixins: [SaleTriggersMixins],
  data() {
    return {
      // open form
      dialog: false,
      // model tabs
      tabs: false,
      // open dialog para disabilitar
      dialogDeactivate: false,
      // main object v-model
      salesTrigger: null,
      // object usado para mostrar gatilho
      // para desativar/ativar
      saleTriggersUpdate: undefined as SaleTrigger | undefined,
      // Array do v-data-table
      // modificado para mostrar os gatilhos
      triggersDisplay: [] as SaleTriggerScreen[],
      // Msg para desativar/ativar o gatilho
      updateMsg: undefined as string | undefined,
      // load
      loadingSale: false,
    };
  },
  methods: {
    prepareTriggers(): void {
      if(this.user.isAnonymous)
        this.buildTriggers();
      else
        this.searchSaleTriggers();
    },
    buildTriggerDisplay(): void {
      if (this.saleTriggers.length > 0) {
        this.triggersDisplay = this.saleTriggers.map(
          (salesTrigger: SaleTrigger, index: number) => {
            let goal: string | undefined = undefined;
            let exchange: string | undefined = undefined;
            let goalFob: string | undefined = undefined;
            let goalExwCbot: string | undefined = undefined;
            let goalExwB3: string | undefined = undefined;
            let goalExw: string | undefined = undefined;
            let exchangeFob: string | undefined = undefined;
            let exchangeExw: string | undefined = undefined;
            if (this.growingSelected.isCattle) {
              // Calc para o boi
              goal = this.calcGoal(salesTrigger.margin, this.growingSelected, salesTrigger.trigger_type);
            } else {
              // Grãos
              if (this.harvestSelected.isSpot) {
                // Calc para safra Spot
                if (this.growingSelected.isCotton) {
                  // Calc para algodão
                  goalFob = this.flatpriceGoalCottonSpot(
                    salesTrigger.margin,
                    this.stateCalculationsSpot.flatpriceFob,
                    this.stateCalculationsSpot.basisFob,
                    salesTrigger.trigger_type
                  );
                  exchangeFob = this.exchangeGoalCottonSpot(
                    salesTrigger.margin,
                    this.stateCalculationsSpot.flatpriceFob,
                    salesTrigger.trigger_type
                  );
                } else {
                  goalFob = this.flatpriceGoalSpot(
                    salesTrigger.margin,
                    this.stateCalculationsSpot.flatpriceFob,
                    this.stateCalculationsSpot.basisFob,
                    "ppe",
                    salesTrigger.trigger_type
                  );
                  if (this.growingSelected.isCorn) {
                    goalExwCbot = this.flatpriceGoalSpot(
                      salesTrigger.margin,
                      this.stateCalculationsSpot.flatpriceExwCbot,
                      this.stateCalculationsSpot.basisExwCbot,
                      "basis",
                      salesTrigger.trigger_type
                    );
                    goalExwB3 = this.flatpriceGoalSpotB3(
                      salesTrigger.margin,
                      this.stateCalculationsSpot.flatpriceExwB3,
                      this.stateCalculationsSpot.basisExwB3,
                      salesTrigger.trigger_type
                    );
                  } else {
                    goalExw = this.flatpriceGoalSpot(
                      salesTrigger.margin,
                      this.stateCalculationsSpot.flatpriceExwCbot,
                      this.stateCalculationsSpot.basisExwCbot,
                      "basis",
                      salesTrigger.trigger_type
                    );
                  }
                  exchangeFob = this.exchangeGoalSpot(
                    salesTrigger.margin,
                    this.stateCalculationsSpot.flatpriceFob,
                    "ppe",
                    salesTrigger.trigger_type
                  );
                  exchangeExw = this.exchangeGoalSpot(
                    salesTrigger.margin,
                    this.stateCalculationsSpot.flatpriceExwCbot,
                    "basis",
                    salesTrigger.trigger_type
                  );
                }
              } else {
                if(this.growingSelected.isCotton) {
                  goal = this.calcGoal(
                    salesTrigger.margin,
                    this.growingSelected,
                    salesTrigger.trigger_type
                  );
                  exchange = this.calcExchange(
                    salesTrigger.margin,
                    this.growingSelected,
                    salesTrigger.trigger_type
                  );
                }
                // Calc para demais safras
                goalFob =this.calcGoal(
                  salesTrigger.margin,
                  this.growingSelected,
                  salesTrigger.trigger_type,
                  'ppe'
                );
                goalExwCbot =this.calcGoal(
                  salesTrigger.margin,
                  this.growingSelected,
                  salesTrigger.trigger_type,
                  'basisLocal'
                );
                goalExw =this.calcGoal(
                  salesTrigger.margin,
                  this.growingSelected,
                  salesTrigger.trigger_type,
                  'basisLocal'
                );
                exchangeFob = this.calcExchange(
                  salesTrigger.margin,
                  this.growingSelected,
                  salesTrigger.trigger_type,
                  'ppe'
                );
                exchangeExw = this.calcExchange(
                  salesTrigger.margin,
                  this.growingSelected,
                  salesTrigger.trigger_type,
                  'basisLocal'
                );

                if(this.growingSelected.isCorn)
                  goalExwB3 =this.calcGoal(
                    salesTrigger.margin,
                    this.growingSelected,
                    salesTrigger.trigger_type,
                    'b3'
                  );
              }
            }
            return new SaleTriggerScreen(
              salesTrigger,
              `G${index + 1}`,
              goal,
              exchange,
              goalFob,
              goalExwCbot,
              goalExwB3,
              goalExw,
              exchangeFob,
              exchangeExw
            );
          }
        );
      }else {
        this.triggersDisplay = [];
      }
    },
    async searchSaleTriggers(): Promise<void> {
      let params: IParams = {
        ownership_id: this.ownershipSelected?.id,
        growing_id: this.growingSelected?.id,
        checkDelete: true,
      };
      if (this.growingSelected.isCattle)
        params.cattle_lot_id = this.cattleLotSelected?.id;
      else {
        if (this.harvestSelected.isSpot) params.harvest_id = "";
        else params.harvest_id = this.harvestSelected?.id;
      }
      // se tem cliente selecionado
      // procura os gatilhos do cliente
      if (this.client) {
        params.client = this.client.id;
      }
      this.loadingSale = true;
      await this.getSaleTriggers(params);
      try {
        this.loadingSale = false;
        // TODO: FORMAT NUMBERS
        // TODO: CREATE AN ARRAY OF DICTS
        this.buildTriggerDisplay();
        // Emit que os gatilhos foram carregados
        this.$emit("loadedTrigger");
      } catch (error) {
        this.loadingSale = false;
        this.$alertError(
          "Houve um problema ao carregar o gatilho, por favor recarregue a página"
        );
        console.error(error);
      }
    },
    updateSaleTriggerList(item: SaleTrigger): void {
      this.$store.commit(
        'changeSaleTriggers',
        item
      );
    },
    removeFromResourceList(item: SaleTrigger): void {
      this.$store.commit(
        'removeSaleTriggers',
        item.id
      );
    },
    reCalcMargin(): void {
      if (this.triggersDisplay?.length) {
        this.triggersDisplay.forEach((st) => {
          st.goal = this.calcGoal(st.margin, this.growingSelected);
          // só recalcula dolar
          // quando não é boi
          if (!this.growingSelected.isCattle)
            st.exchange = this.calcExchange(st.margin, this.growingSelected);
        });
      }
    },
    openForm(): void {
      if (!this.disableAddTriggers) {
        const form = this.$refs["form"] as IFormFuncs<SaleTrigger>;
        if (form && form.checkStock) {
          form.checkStock(this.stockLeft);
          this.dialog = true;
        }
      } else {
        this.$alertError(this.disableAddMsg);
      }
    },
    edit(usaletrigger: SaleTriggerScreen): void {
      const form = this.$refs["form"] as IFormFuncs<SaleTrigger>;
      if (form && form.toUpdate && form.checkStock) {
        //Estoque deve ser veirifcado antes!
        form.checkStock(this.stockLeft);
        form.toUpdate(new SaleTrigger(usaletrigger));
      }
    },
    deactivate(usaletrigger: SaleTriggerScreen): void {
      this.saleTriggersUpdate = usaletrigger;
      this.updateMsg = this.saleTriggersUpdate.active ? "Desativar" : "Ativar";
      this.updateMsg += " o gatilho?";
      this.dialogDeactivate = true;
    },
    async confirmDelete(dsaletrigger: SaleTriggerScreen): Promise<void> {
      try {
        if (this.user?.isAnonymous) {
          // DELETE from Store
          await this.deleteSaleTriggersStore(dsaletrigger.id);
          this.buildTriggers();
        } else {
          // DELETE from BD
          this.setResource("sale_trigger");
          await this.deleteSaleTriggers(dsaletrigger.id);
          this.removeFromResourceList(dsaletrigger);
          this.buildTriggerDisplay();
        }
        this.$alertSuccess("Gatilho deletedo com sucesso!");
      } catch (err) {
        this.$alertError("Houve um problema ao deletar o gatilho!");
        console.log(err);
      }
    },
    async confirmDeactivate(): Promise<void> {
      // Desativa o gatilho
      if (this.saleTriggersUpdate) {
        this.saleTriggersUpdate.active = !this.saleTriggersUpdate.active;
        try {
          if (this.user?.isAnonymous) {
            // Atualizad gatilho no store
            await this.updateSaleTriggersStore(this.saleTriggersUpdate);
            this.buildTriggers();
          } else {
            // atualiza gatilho no BD
            if(this.saleTriggersUpdate.id) {
              await crudService.patch<SaleTrigger>(
                SaleTrigger,
                SaleTrigger.getResource(),
                this.saleTriggersUpdate.id,
                { active: this.saleTriggersUpdate.active }
              );
              this.updateSaleTriggerList(this.saleTriggersUpdate)
              this.$alertSuccess("Gatilho alterado!");

            } else {
              this.$alertError("Não foi possível alterar o gatilho");
            }
          }
        } catch (err) {
          this.$alertError("Houve um problema ao alterar o gatilho!");
          console.log(err);
        } finally {
          this.dialogDeactivate = false;
        }
      }
    },

    // Anonymous ---------------------
    buildTriggers(): void {
      this.triggersDisplay = this.saleTriggers.map(
        (salesTrigger: SaleTrigger, index: number) => {
          return new SaleTriggerScreen(
            salesTrigger,
            `G${index + 1}`,
            this.calcGoal(salesTrigger.margin, this.growingSelected),
            this.calcExchange(salesTrigger.margin, this.growingSelected)
          );
        }
      );
      // Emit que os gatilhos foram carregados
      this.$emit("loadedTrigger");
    },
  },
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    }),
    sumSell(): number {
      if (this.saleTriggers?.length) {
        return this.saleTriggers.reduce(
          (accumulator: number, element: SaleTrigger) =>
            accumulator + element.to_sell,
          0
        );
      }
      return 0;
    },
    stockLeft(): number {
      if (this.sumSell) return 100 - this.sumSell;
      return 100;
    },
    disableAddTriggers(): boolean {
      if (
        this.growingSelected.isCattle &&
        this.profitCattleLotPriceSelected.is_forward_contract
      ) {
        return true;
      }
      if (this.sumSell) {
        if (this.sumSell >= 100) return true;
      }
      return false;
    },
    disableAddMsg() {
      if (
        this.growingSelected.isCattle &&
        this.profitCattleLotPriceSelected.is_forward_contract
      ) {
        return "Gatilhos desativados pelo contrato a termo";
      }
      if (this.sumSell) {
        if (this.sumSell >= 100)
          return "Não é possível adicionar outro gatilho, estoque máximo alcançado";
      }
      return "Adicionar novo gatilho";
    },
  },
});
