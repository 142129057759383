
import Vue from "vue";

import Business from "@/utilsBusiness";
import ECharts from "vue-echarts";

import { mapGetters } from "vuex"; // mapState
import { exception } from "vue-gtag";
import {
  HistoryGrowingData,
  HistoryOwnershipData,
  HistoryProfit,
  IHistoryGraphOptions,
  IDataAsset,
  ISaleTriggerGraph,
} from "@/business/HistoryProfit";
import { chartsService } from "@/services/charts";
import { IHistoryProfitParams } from "@/types/service-types";
import { isValidKeys } from "@/utilsObject";

import Ownership from "@/models/ownership";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";
import CattleLot from "@/models/cattleLot";
import User from "@/models/user";
import SaleTrigger from "@/models/saleTrigger";

export interface IHistoryProfitComp {
  build?: () => void;
  buildSimple?: () => void;
}

// import { getWatermark } from "../../../utils";
// const canvas = getWatermark();
export default Vue.extend({
  props: {
    ownershipSelected: Ownership,
    harvestSelected: Harvest,
    growingSelected: Growing,
    cattleLotSelected: CattleLot,
    loading: Boolean,
    client: User,
  },
  data() {
    return {
      inset: false,
      // Configs para gerar o grafico
      chartOptionsLine: undefined as IHistoryGraphOptions | undefined,
      // processamento de busca e criação do dados
      loadingGraph: false,
      // gatilhos de venda do usuário
      triggers: undefined as ISaleTriggerGraph[] | undefined,
      // data processados do grafico
      data: {} as IDataAsset[],
      // periodo da safra
      harvestPeriod: "actual",
      // armazena o response da API com os dados
      response_data: undefined as HistoryProfit | undefined,
      // ID do cliente selecionado no momento
      client_id: undefined as number | undefined,
      // para mensagem de erro
      // informa que houve problema
      error: false,
    };
  },
  computed: {
    ...mapGetters({
      saleTriggers: "getSaleTriggers",
      stateCalculations: "getCalculationState",
      stateCalculationsSpot: "getCalculationStateSPOT",
      stateCalculationsCattle: "getCalculationStateCattle",
      profitOwnershipSelected: "getProfitOwnershipSelected",
      cottonConfigs: "getCottonConfigs",
      pricingSelected: "getPrincingSelected",
    }),
  },
  methods: {
    grainsRentability(flatprice: number, dollar = 0): number {
      if (!this.harvestSelected.isSpot) {
        if (this.growingSelected.isCotton) {
          return (
            Business.rentability_cotton(
              flatprice,
              this.stateCalculations.productivity,
              this.cottonConfigs,
              "_percent"
            ) || 0
          );
        }
        if(this.pricingSelected === "basisB3") {
          return Business.rentabilityB3(
            flatprice,
            this.stateCalculations.productivity,
            "_percent"
          );
        }
        return (
          Business.rentability(
            flatprice,
            this.stateCalculations.productivity,
            "_percent"
          ) || 0
        );
      } else {
        return (
          Business.spotPricing(
            flatprice,
            dollar,
            this.stateCalculationsSpot.logistic,
            this.stateCalculationsSpot.constants,
            this.growingSelected.symbol,
            this.pricingSelected
          ) || 0
        );
      }
    },
    cattleRentability(price: number): number {
      return (
        Business.cattleRentability(
          price + this.stateCalculationsCattle.basis,
          this.stateCalculationsCattle.carcassProductivity,
          "_percent"
        ) || 0
      );
    },
    async buildSimple(): Promise<void> {
      this.loadingGraph = true;
      this.error = false;
      this.calcMarginTriggers();
      if (this.client) this.client_id = this.client.id;
      this.response_data = await chartsService.getClosedPricesN1(
        this.client_id
      );
      this.loadingGraph = false;
      // extract data
      let growing = undefined;
      let ownership = undefined;
      if (this.growingSelected.isCattle) {
        growing = this.response_data?.cattle;
        try {
          if (growing?.lot) this.generateGraphCattle(growing.lot);
          else throw exception;
        } catch (e) {
          console.warn("no data!");
          this.error = true;
        }
      } else {
        if (this.growingSelected.isCotton) {
          growing = this.response_data.cotton;
        } else if (this.growingSelected.isCorn) {
          growing = this.response_data.corn;
        } else {
          growing = this.response_data.soybean;
        }
        if (this.harvestSelected.is_current) {
          ownership = growing?.actual;
        } else if (this.harvestSelected.is_future) {
          ownership = growing?.future;
        } else if (this.harvestSelected.id === 0) {
          ownership = growing?.spot;
        }
        try {
          if (ownership) this.generateGraphGrains(ownership);
          else throw exception;
        } catch (e) {
          console.warn("Sem dados da safra!");
          this.error = true;
        }
      }
    },
    async build(): Promise<void> {
      this.loadingGraph = true;
      this.error = false;
      this.calcMarginTriggers();

      if (this.harvestSelected) {
        if (this.harvestSelected.is_current) {
          this.harvestPeriod = "actual";
        } else if (this.harvestSelected.is_future) {
          this.harvestPeriod = "future";
        } else if (this.harvestSelected.isSpot) {
          this.harvestPeriod = "spot";
        }
      }

      if (this.client) this.client_id = this.client.id;

      let params: IHistoryProfitParams = {
        ownership: 0,
        growing: this.growingSelected.isCattle
          ? "cattle"
          : this.growingSelected.isCorn
          ? "corn"
          : this.growingSelected.isCotton
          ? "cotton"
          : "soybean",
        pricing: this.pricingSelected,
      };
      if (typeof this.ownershipSelected.id !== 'undefined')
        params.ownership = this.ownershipSelected.id;
      else console.warn("HistoryProfit: ownership without id: ", this.ownershipSelected.name);
      if (this.client_id) params.client = this.client_id;
      if (this.harvestSelected) params.harvest = this.harvestPeriod;
      if (this.cattleLotSelected) params.cattleLot = this.cattleLotSelected.id;

      this.response_data = await chartsService.getClosedPrices(params);
      this.checkMissingData();
      this.loadingGraph = false;
      // extract data
      let growingData = undefined;
      let ownershipData = undefined;
      if (this.growingSelected.isCattle) {
        growingData = this.response_data.cattle;
        if (growingData?.lot) {
          this.generateGraphCattle(growingData.lot);
        } else {
          console.log("no data!");
          this.error = true;
        }
      } else {
        if (this.growingSelected.isCotton) {
          growingData = this.response_data.cotton;
        } else if (this.growingSelected.isCorn) {
          growingData = this.response_data.corn;
        } else {
          growingData = this.response_data.soybean;
        }
        // Pega os info da propriedade
        // HistoryOwnershipData
        if (
          growingData &&
          isValidKeys(HistoryGrowingData, this.harvestPeriod)
        ) {
          ownershipData = growingData[this.harvestPeriod];
          if (ownershipData) {
            this.generateGraphGrains(ownershipData);
          }
        } else {
          console.log("no data!");
          this.error = true;
        }
      }
    },
    checkMissingData(){
      let growingData = undefined;
      let harvestData = undefined;
      let basisData = undefined;
      let currencyData = undefined;
      let seData = undefined;
      let lastBasis = undefined;
      let lastCurrency = undefined;
      let lastSe = undefined;

      // CHECK CATTLE
      if (this.growingSelected.isCattle && this.response_data) {
        growingData = this.response_data.cattle;
        harvestData = growingData?.lot;
      }
      // CHECK COTTON
      if (this.growingSelected.isCotton && this.response_data) {
        growingData = this.response_data.cotton;
        harvestData = this.harvestPeriod === 'actual' ? growingData?.actual : growingData?.future;
      }
      // CHECK CORN
      if (this.growingSelected.isCorn && this.response_data) {
        growingData = this.response_data.corn;
        harvestData = this.harvestPeriod === 'actual' ? growingData?.actual : growingData?.future;
      }
      // CHECK SOYBEAN
      if (this.growingSelected.isSoybean && this.response_data) {
        growingData = this.response_data.soybean;
        harvestData = this.harvestPeriod === 'actual' ? growingData?.actual : growingData?.future;
      }
      basisData = harvestData?.data.basis ? harvestData.data.basis : [];
      currencyData = harvestData?.data.currency ? harvestData.data.currency : [];
      seData = harvestData?.data.culture ? harvestData.data.culture : [];
      lastBasis = basisData?.length ? basisData?.slice(-1) : null;
      lastCurrency = currencyData?.length ? currencyData?.slice(-1) : null;
      lastSe = seData?.length ? seData?.slice(-1) : null;
      if (lastBasis) console.log(this.pricingSelected, ' Last Basis: ', lastBasis[0].date);
      if (lastCurrency) console.log(this.pricingSelected, ' Last Currency: ', lastCurrency[0].date);
      if (lastSe) console.log(this.pricingSelected, ' Last Se: ', lastSe[0].date);
    },
    generateGraphGrains(ownership: HistoryOwnershipData) {
      if (
        this.pricingSelected === "ppe" ||
        this.pricingSelected === "basisCbot"
      ) {
        this.data = (ownership.data.culture || [])
          .map((item) => {
            const basisData = ownership.data?.basis?.find(
              (b) => b.dateWithoutGMT === item.dateWithoutGMT
            );
            const basis = basisData ? basisData.valueBy100 : false;
            const currencyData = ownership.data?.currency?.find(
              (b) => b.dateWithoutGMT === item.dateWithoutGMT
            );
            const currency = currencyData ? currencyData.value : false;
            const date = new Date(item.dateWithoutGMT).toLocaleDateString(
              "pt-BR",
              {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }
            );
            if ((!basis || !currency) || (item.value < 0)) {
              return {
                date: date,
                value: 0,
              };
            }
            return {
              date: date,
              value: parseFloat(
                this.grainsRentability(
                  (this.growingSelected.isCotton
                    ? item.value
                    : item.valueBy100) + basis,
                  currency
                ).toFixed(2)
              ),
            };
          })
          .filter((e) => !!e && !!e.value);
        this.prepareGraph();
      } else {
        // Fluxo Spot B3 EXW
        this.data = (ownership.data.culture || [])
          .map((item) => {
            const basisData = ownership.data?.basis?.find(
              (b) => b.dateWithoutGMT === item.dateWithoutGMT
            );
            const basis = basisData ? basisData.value : false;
            const date = new Date(item.dateWithoutGMT).toLocaleDateString(
              "pt-BR",
              {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }
            );
            if (!basis) {
              return {
                date: date,
                value: 0,
              };
            }
            return {
              date: date,
              value: parseFloat(
                this.grainsRentability(item.value + basis).toFixed(2)
              ),
            };
          })
          .filter((e) => !!e && !!e.value);
        this.prepareGraph();
        }
    },
    generateGraphCattle(lot: HistoryOwnershipData) {
      this.data = (lot.data.culture || [])
        .map((item) => {
          const date = new Date(item.dateWithoutGMT).toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          });
          if (item.value < 0) {
            return {
              date: date,
              value: 0,
            };
          }
          return {
            date: date,
            value: parseFloat(this.cattleRentability(item.value).toFixed(2)),
          };
        })
        .filter((e) => !!e && !!e.value);
      if (this.data) {
        this.prepareGraph();
      } else {
        this.chartOptionsLine = undefined;
        this.$alertError(
          `Sem histórico de preços da cultura ${this.$t(
            this.growingSelected.name
          )} para o gráfico`
        );
        this.error = true;
      }
    },
    setGradientColors() {
      let lastTwoDaysData = this.data.slice(-2)
      if (lastTwoDaysData[0].value > lastTwoDaysData[1].value) {
        // queda
        return new ECharts.graphic.LinearGradient(0,0,0,1,[
            {offset: 0, color: "rgba(229, 57, 53, 0.7)",},
            {offset: 1, color: "rgba(229, 57, 53, 0.1)",},
          ])
      } else if (lastTwoDaysData[0].value < lastTwoDaysData[1].value) {
        // alta
        return new ECharts.graphic.LinearGradient(0,0,0,1,[
            {offset: 0, color: "rgba(67, 160, 71, 1)",},
            {offset: 1, color: "rgba(124, 179, 66, 0.4)",},
          ])
      } else if (lastTwoDaysData[0].value === lastTwoDaysData[1].value){
        // sem alteracao
        return new ECharts.graphic.LinearGradient(0,0,0,1,[
            {offset: 0, color: "rgba(255, 143, 0, 0.7)",},
            {offset: 1, color: "rgba(255, 143, 0, 0.1)",},
          ])
      } else {
        // sem comparacao
        return new ECharts.graphic.LinearGradient(0,0,0,1,[
            {offset: 0, color: "rgba(255, 143, 0, 0.7)",},
            {offset: 1, color: "rgba(255, 143, 0, 0.1)",},
          ])
      }
    },
    setColors() {
      let lastTwoDaysData = this.data.slice(-2)
      if (lastTwoDaysData[0].value > lastTwoDaysData[1].value) {
        // queda
        return [
          '#e53935',
          '#33691E',
          '#B71C1C'
        ]
      } else if (lastTwoDaysData[0].value < lastTwoDaysData[1].value) {
        // alta
        return [
          '#64DD17',
          '#33691E',
          '#B71C1C'
        ]
      } else if (lastTwoDaysData[0].value === lastTwoDaysData[1].value){
        // sem alteracao
        return [
          '#EF6C00',
          '#33691E',
          '#B71C1C'
        ]
      } else {
        // sem comparacao
        return [
          '#EF6C00',
          '#33691E',
          '#B71C1C'
        ]
      }
    },
    prepareGraph() {
      if (this.data && this.data.length > 0) {
        let colors = this.setColors();
        let chartTitle = "Rentabilidade";
        let serieName = "Rentabilidade";

        if (this.harvestSelected.id !== 0) {
          chartTitle = "Rentabilidade";
          serieName = "Rentabilidade";
        } else if (
          this.harvestSelected.isSpot &&
          !this.growingSelected.isCattle
        ) {
          chartTitle = "Histórico de preço";
          serieName = "Valor";
        }

        const DataMax = this.data
          .map((e) => e.value)
          .reduce(function (a, b) {
            return Math.max(a, b);
          });
        const DataMin =
          this.data
            .map((e) => e.value)
            .reduce(function (a, b) {
              return Math.min(a, b);
            }) || 0;

        let triggerMax = 0;
        if (this.triggers) {
          triggerMax =
            this.triggers
              .map((e) => e.yAxis)
              .reduce(function (a, b) {
                return Math.max(a, b);
              }) || 0;
        }
        let triggerMin = 0;
        if (this.triggers) {
          triggerMin =
            this.triggers
              .map((e) => e.yAxis)
              .reduce(function (a, b) {
                return Math.min(a, b);
              }) || 0;
        }
        const minMin = Math.min(DataMin, triggerMin);
        const minYaxes = minMin <= 0 ? Math.floor(minMin / 100) * 100 : 0;

        const maxMax = Math.max(DataMax, triggerMax);
        const maxYaxes = maxMax >= 100 ? Math.ceil(maxMax / 100) * 100 : 100;

        this.chartOptionsLine = {
          title: {
            text: chartTitle,
            textAlign: "center",
            left: "center",
          },
          color: colors,
          legend: {
            data: ['','Margin Limit','Stop Limit'],
            top: '10%'
          },
          tooltip: {
            trigger: "axis",
          },
          axisPointer: {
            link: {
              xAxisIndex: "all",
            },
            label: {
              backgroundColor: "#777",
            },
          },
          grid: [
            {
              left: "10%",
              right: "10%",
              bottom: "20%",
              top: "20%",
              height: "60%",
            },
          ],
          xAxis: {
            data: this.data.map((e) => e.date),
          },
          yAxis: {
            type: "value",
            splitLine: { show: false },
            axisLabel: {
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
              formatter: (param: string) => {
                // GRAOS FlatPrice ($)
                if (!this.harvestSelected.isSpot) {
                  return param + " %";
                  // Boi B3 (R$)
                } else {
                  return "R$ " + param;
                }
              },
              fontSize: 12,
            },
            scale: true,
            max: this.triggers ? maxYaxes : undefined,
            min: this.triggers ? minYaxes : undefined,
            splitNumber: 5,
            boundaryGap: [0.2, 0.2],
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
              color: this.$vuetify.theme.dark ? "#fff" : "#000",
              fontWeight: "bolder",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
            axisTick: {
              interval: 0,
              textStyle: {
                color: this.$vuetify.theme.dark ? "#fff" : "#000",
              },
            },
          },
          toolbox: {
            show: true,
            right: "10%",
            feature: {
              // dataView: {show: true, readOnly: false},
              magicType: {
                show: true,
                type: ["line", "bar"],
                title: {
                  line: "Linhas",
                  bar: "Barras",
                },
                iconStyle: {
                  borderWidth: 1,
                },
              },
              restore: { show: true, title: "Atualizar" },
              saveAsImage: { show: true, title: "Salvar" },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
              handleIcon:
                "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
              handleSize: "80%",
              handleStyle: {
                color: "#fff",
                shadowBlur: 3,
                shadowColor: "rgb(29, 41, 81)",
                shadowOffsetX: 2,
                shadowOffsetY: 2,
              },
            },
          ],
          series: [
            {
              name: serieName,
              type: "line",
              data: this.data.map((e) => e.value),
              areaStyle: {
                color: this.setGradientColors(),
              },
              smooth: true,
            },
            {
              name: 'Margin Limit',
              data: [],
              type: "line",
              smooth: true,
              markLine: {
                data: this.triggers?.filter((el) => el?.triggerType === 'MARGIN_LIMIT'),
                lineStyle: {
                  normal: {
                    color: colors[1],
                    width: 3,
                  },
                },
              },
            },
            {
              name: 'Stop Limit',
              type: "line",
              smooth: true,
              data: [],
              markLine: {
                data: this.triggers?.filter((el) => el?.triggerType === 'STOP_LIMIT'),
                lineStyle: {
                  normal: {
                    color: colors[2],
                    width: 3,
                  },
                },
              },
            },
          ],
          animationDuration: 1000,
        };
      } else {
        this.chartOptionsLine = undefined;
        this.$alertError(
          "Não foi possível calcular o histórico de rentabilidade"
        );
        this.error = true;
      }
    },
    calcMarginTriggers(): void {
      if (this.ownershipSelected && this.ownershipSelected.id !== 0) {
        // Verifica se a propriedade é anonima (-1)
        // para pegar os gatilhos de acordo
        let ownerSaleTriggers =
          this.ownershipSelected.id === -1
            ? this.saleTriggers.filter((el: SaleTrigger) => {
                return (
                  this.harvestSelected.id === el.harvest_id &&
                  this.growingSelected.id === el.growing_id
                );
              })
            : this.saleTriggers.map((el: SaleTrigger) => {
                if (this.ownershipSelected.id === el.ownership_id) return el;
              });
        // Verifica se a fazenda tem gatilhos
        if (ownerSaleTriggers && ownerSaleTriggers.length > 0) {
          if (this.growingSelected.isCattle) {
            this.triggers = ownerSaleTriggers.map((el: SaleTrigger) => ({
              yAxis: Business.triggersMarginCattle(
                el.margin,
                this.stateCalculations.productionCost
              ),
              triggerType: el.trigger_type
            }));
          } else if (this.harvestSelected.isSpot) {
            this.triggers = ownerSaleTriggers.map((el: SaleTrigger) => ({
              yAxis: el.margin,
              triggerType: el.trigger_type
            }));
          } else if (this.growingSelected.isCotton) {
            this.triggers = ownerSaleTriggers.map((el: SaleTrigger) => ({
              yAxis: Business.triggersMarginCotton(
                el.margin,
                this.stateCalculations.productionCost
              ),
              triggerType: el.trigger_type
            }));
          } else {
            this.triggers = ownerSaleTriggers.map((el: SaleTrigger) => ({
              yAxis: Business.triggersMargin(
                el.margin,
                this.profitOwnershipSelected.productivity,
                this.profitOwnershipSelected.exw_cost_rs_sc
              ),
              triggerType: el.trigger_type
            }));
          }
        } else {
          this.triggers = undefined;
        }
      } else {
        this.triggers = undefined;
      }
    },
  },
});
