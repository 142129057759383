
import Vue from "vue";

import ProfitN1 from "@/models/profitN1";
import Ownership from "@/models/ownership";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";

import { profitService } from "@/services/profit";
import { mapGetters, mapActions } from "vuex"; // mapState
import { real_op, dollar_op, real_ha_op } from "@/currencyOptions";
import { IFormFuncs } from "@/types/form-types";
import { dateISONotNull } from "@/utilsDate";
import { LotInfoProfit } from "@/business/CattleProfit";
import { CattlePricing } from "@/business/Pricing";
import { decimalBRZToNumber } from "@/utilsNumber";

export default Vue.extend({
  props: {
    ownershipSelected: Ownership,
    harvestSelected: Harvest,
    growingSelected: Growing,
    gColor: String,
  },
  data() {
    return {
      // Controla o dialog
      dialog: false,

      currencyReal: real_op,
      currencyRealHa: real_ha_op,
      currencyDollar: dollar_op,

      profitN1: new ProfitN1(),

      productionRules: [(v: string) => !!v || "Informe o custo de produção"],
      productivityRules: [(v: string) => !!v || "Informe a produtividade"],
      totalRules: [(v: string) => !!v || "Informe a área total plantada"],
      logisticRules: [(v: string) => !!v || "Informe o custo do frete"],
      fobbingRules: [(v: string) => !!v || "Informe o Fobbings"],
      rules: {
        required: (value: string) => !!value || "Campo obrigatório.",
        norequired: () => true,
        nozero: (value: string) => {
          if (!value) return true;
          return !!decimalBRZToNumber(value) || "Campo obrigatório.";
        },
      },
    };
  },
  mounted() {
    if (this.growingSelected.isCotton) this.profitN1.fobbings = 120;
  },
  methods: {
    ...mapActions({
      updateProfitOwnershipSelected: "UPDATE_PROFIT_OWNERSHIP_SELECTED",
      updateProfitIncomeSelected: "UPDATE_PROFIT_INCOME_SELECTED",
    }),
    async saveProfit(): Promise<void> {
      if (
        this.validate() &&
        this.ownershipSelected.id &&
        this.growingSelected.id
      ) {
        if (this.growingSelected.isCattle) {
          if (this.profitN1.slaughter_date) {
            this.profitN1.slaughter_date = dateISONotNull(
              this.profitN1.slaughter_date,
              true
            );
          }
        } else {
          this.profitN1.harvest_id = this.harvestSelected.id;
        }

        this.profitN1.user_id = this.$store.getters.getLoggedUser.id;
        this.profitN1.ownership_id = this.ownershipSelected.id;
        this.profitN1.growing_id = this.growingSelected.id;
        try {
          await profitService.saveSimpleProfit(this.profitN1);
          this.$emit("searchProfit");
          this.close();
        } catch (error) {
          this.$alertError("Houve um problema ao salvar o cenário");
        }
      }
    },
    updateLotFromProfit(
      profitCattleLotSelected: LotInfoProfit,
      profitCattleLotPriceSelected: CattlePricing
    ): void {
      let slaughter_date = new Date(profitCattleLotSelected.slaughter_date)
        .toISOString()
        .substr(0, 10);
      this.profitN1.production_cost = profitCattleLotSelected.arroba_total_cost;
      this.profitN1.animal_amount = profitCattleLotSelected.animal_amount;
      this.profitN1.final_weight_finishing =
        profitCattleLotSelected.slaughter_weight;
      this.profitN1.yield_carcass = profitCattleLotSelected.yield_carcass;
      this.profitN1.slaughter_date = slaughter_date;
      this.profitN1.b3_costs = profitCattleLotPriceSelected.b3_costs;
      this.profitN1.bonus = profitCattleLotPriceSelected.bonus;
      this.profitN1.basis_fit = profitCattleLotPriceSelected.basis_fit;
      this.profitN1.basis = profitCattleLotPriceSelected.basis;
      this.profitN1.is_forward_contract =
        profitCattleLotPriceSelected.is_forward_contract;
      this.dialog = true;
    },
    reset(): void {
      const form = this.$refs["mainForm"] as IFormFuncs<ProfitN1>;
      if (form && form.resetValidation) form.resetValidation();
    },
    validate(): boolean {
      const form = this.$refs["mainForm"] as IFormFuncs<ProfitN1>;
      if (form && form.validate) return form.validate();
      return false;
    },
    close(): void {
      this.dialog = false;
      this.resetValues();
      this.reset();
    },
    timesAmountPrice(value: number) {
      if (this.profitN1.sold_rs_sc && this.profitN1.sold_us_sc) {
        this.profitN1.sold_value_rs = this.profitN1.sold_rs_sc * value;
        this.profitN1.sold_value_us = this.profitN1.sold_us_sc * value;
      }
    },
    timesPriceRSAmount(price: number): void {
      this.profitN1.sold_value_rs = this.profitN1.sold_amount * price;
    },
    timesPriceUSAmount(price: number): void {
      this.profitN1.sold_value_us = this.profitN1.sold_amount * price;
    },
    resetValues(): void {
      this.profitN1 = new ProfitN1();
    },
  },
  computed: {
    ...mapGetters({
      profitOwnershipSelected: "getProfitOwnershipSelected",
      profitIncomeSelected: "getProfitIncomeSelected",
    }),
  },
  watch: {
    sold_rs_sc(val): void {
      this.timesPriceRSAmount(val);
    },
    sold_us_sc(val): void {
      this.timesPriceUSAmount(val);
    },
  },
});
