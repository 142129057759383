
import Vue from "vue";

import { mapGetters, mapActions } from "vuex"; // mapState
import { userService } from "@/services/user";

export interface IFirstAccessComp {
  dialog?: boolean;
}

export default Vue.extend({
  data: () => ({
    dialog: false as boolean,
    inset: false as boolean,
  }),
  methods: {
    ...mapActions({
      getMe: "LOAD_USER",
    }),
    async updateUser(): Promise<void> {
      this.user.is_first_access = false;
      // atualiza a config is_first_access do user
      // para false
      await userService.saveUser(this.user);
      // busca user atualizado
      // e insere no local storage
      this.getMe();
      if (this.user.isN1) {
        // Se for n1 abre o form da propriedade
        this.$emit("openOwnershipFirstAccess");
      } else {
        // Se não, manda para o cadastro da propriedade
        this.$router.push("/crud/ownership");
      }
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
    }),
    mainMessage() {
      if (this.user.isN1) {
        return "Antes de começar a calcular sua rentabilidade, cadastre sua propriedade no Pro market!";
      } else {
        return "Antes de começar a calcular sua rentabilidade, você deve preencher os cadastros da sua propriedade!";
      }
    },
  },
});
