
import Vue from 'vue';

export default Vue.extend({
  name: "DisplayTriggerType",
  props: {
    triggerType: {
      type: String,
      default: 'MARGIN_LIMIT'
    }
  },
  computed: {
    capitalizeWords(): string {
      if (this.triggerType) {
        let wordsArr = this.triggerType.split('_');
        let capitalized = wordsArr.map(element => {
          return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
        });
        return capitalized.join(' ');
      }
      return this.triggerType;
    },
    icon(): string {
      return `mdi-trending-${this.triggerType === 'MARGIN_LIMIT' ? 'up' : 'down'}`;
    },
    iconColor(): string {
      return this.triggerType === 'MARGIN_LIMIT' ? 'success': 'red';
    },
    textClasses(): string {
      let classes = 'font-weight-black';
      if(this.triggerType === 'MARGIN_LIMIT') classes = `${classes} green--text`;
      else classes = `${classes} red--text`;
      return classes;
    }
  },
});
