
import Vue, { VueConstructor } from "vue";

import IncomeForm from "@/components/crud/forms/IncomeForm.vue";
import Harvest from "@/models/harvest";
import Growing from "@/models/growing";
import Ownership from "@/models/ownership";
import CattleLot from "@/models/cattleLot";
import ProfitMixins from "@/mixins/profit";

import { mapGetters, mapActions } from "vuex"; // mapState
import { real_op, dollar_op } from "@/currencyOptions";
import { IIncomeFormFunc } from "@/business/OperationIncome";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof ProfitMixins>>
).extend({
  props: {
    ownershipSelected: Ownership,
    harvestSelected: Harvest,
    growingSelected: Growing,
    cattleLotSelected: CattleLot,
    cattleLotList: Array,
    loading: Boolean,
    showAdd: Boolean,
    allowEditions: Boolean,
  },
  /**From mixin:
   * - priceReal
   * - priceDollar
   * - soldReal
   * - soldDollar
   * - statementValueReal
   * - statementValueDollar
   * - revenuePriceReal
   * - revenuePriceDollar
   * - revenueValueReal
   * - revenueValueDollar
   */
  mixins: [ProfitMixins],
  data() {
    return {
      inset: false,
      // form crud income
      dialog: false,
      // options para o real
      currencyReal: real_op,
      // options para o dolar
      currencyDollar: dollar_op,
      // tabs do algodão
      tabs: false,
    };
  },
  components: {
    IncomeForm: IncomeForm,
  },
  methods: {
    ...mapActions({
      getIncomes: "SEARCH_CRUD",
      getResource: "SIMPLE_SEARCH",
      resetSearch: "RESET_SEARCH",
      deleteIncome: "DELETE",
    }),
    openForm(): void {
      const form = this.$refs["form"] as IIncomeFormFunc;
      if (form && form.callFromProfit)
        if (
          this.ownershipSelected.id &&
          this.growingSelected.id &&
          (this.harvestSelected.id || this.harvestSelected.isSpot)
        )
          form.callFromProfit();
        else {
          this.$alertError("Erro ao coletar infomações para o formlário");
          console.error("no id in ownership, growing or harvest");
        }
      else {
        this.$alertError("Ocorreu um erro ao abrir o formulário");
        console.error("form object", form);
      }
    },
    searchIncomes(): void {
      let payload = {
        resource: "income",
        params: {
          ownership_id: this.ownershipSelected.id,
          harvest_id: this.harvestSelected.id,
          growing_id: this.growingSelected.id,
          type: "RECEIVED",
        },
      };
      this.getResource(payload).then((result) => {
        this.incomes = result;
      });
    },
    timesAmountPrice(price: number): void {
      if (this.growingSelected.isCotton) {
        this.profitIncomeSelectedFiber.sold_value_rs =
          this.profitIncomeSelectedFiber.sold_rs_sc * price;
        this.profitIncomeSelectedFiber.sold_value_us =
          this.profitIncomeSelectedFiber.sold_us_sc * price;
      } else {
        this.profitIncomeSelected.sold_value_rs =
          this.profitIncomeSelected.sold_rs_sc * price;
        this.profitIncomeSelected.sold_value_us =
          this.profitIncomeSelected.sold_us_sc * price;
      }
    },
    timesPriceRSAmount(price: number): void {
      if (this.growingSelected.isCotton) {
        this.profitIncomeSelectedFiber.sold_value_rs =
          this.profitIncomeSelectedFiber.sold_amount * price;
      } else {
        this.profitIncomeSelected.sold_value_rs =
          this.profitIncomeSelected.sold_amount * price;
      }
    },
    timesPriceUSAmount(price: number): void {
      if (this.growingSelected.isCotton) {
        this.profitIncomeSelectedFiber.sold_value_us =
          this.profitIncomeSelectedFiber.sold_amount * price;
      } else {
        this.profitIncomeSelected.sold_value_us =
          this.profitIncomeSelected.sold_amount * price;
      }
    },
  },
  computed: {
    ...mapGetters({
      stateCalculations: "getCalculationState",
      incomes: "getProIncomes",
      quotations: "getProQuotations",
      profitIncomeSelected: "getProfitIncomeSelected",
      profitIncomeSelectedSeed: "getProfitIncomeSelectedSeed",
      cottonConfig: "getCottonConfigs",
    }),
    unitSpot(): string {
      return this.growingSelected.isCotton ? '@' : 'sc'
    },

    // Cotton
    seedPriceRs(): number {
      return this.cottonConfig.seed_price / 1000;
    },
    seedPriceUs(): number {
      return this.seedPriceRs / this.stateCalculations.dollar;
    },
    statementSeedValueRs(): number {
      return this.profitIncomeSelectedSeed.statement_amount * this.seedPriceRs;
    },
    statementSeedValueUs(): number {
      return this.profitIncomeSelectedSeed.statement_amount * this.seedPriceUs;
    },
    revenueSeedRs(): number {
      return ((this.profitIncomeSelectedSeed.sold_rs_kg * this.profitIncomeSelectedSeed.sold_amount) + (this.seedPriceRs * this.profitIncomeSelectedSeed.statement_amount)) /
        (this.profitIncomeSelectedSeed.sold_amount + this.profitIncomeSelectedSeed.statement_amount);
    },
    revenueSeedUs(): number {
      return ((this.profitIncomeSelectedSeed.sold_us_kg * this.profitIncomeSelectedSeed.sold_amount) + (this.seedPriceUs * this.profitIncomeSelectedSeed.statement_amount)) /
        (this.profitIncomeSelectedSeed.sold_amount + this.profitIncomeSelectedSeed.statement_amount);
    },
    revenueSeedValueRs(): number {
      return (this.profitIncomeSelectedSeed.sold_amount + this.profitIncomeSelectedSeed.statement_amount) * this.revenueSeedRs;
    },
    revenueSeedValueUs(): number {
      return (this.profitIncomeSelectedSeed.sold_amount + this.profitIncomeSelectedSeed.statement_amount) * this.revenueSeedUs;
    },
  },
});
